.tag {
  color: var(--text-primary-color);
  border-radius: var(--border-radius-small);
  background: var(--body-color);
  display: inline-block;
  padding: 0 6px;
  font-weight: 500;
  margin: 4px;
}

.tagName{
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagName, .crossIcon {
  display: inline-block;
  vertical-align: middle;
}

.crossIcon {
  color: var(--text-primary-light-color);
  margin: 0 8px;

  &:hover {
    color: var(--danger-color);
  }
}
