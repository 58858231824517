.prevContainer {
  position: relative;
  width: 84px;
  height: 84px;
  margin: 11px 5px;

  .prevWrap {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    background: #EFF2F4;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      position: absolute;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  .deleteButton {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background: #d5d5d56b;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .deleteButton {
      visibility: visible;
    }
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.uploadButton {
  width: 95px;
}

.uploadButtonText {
  font-size: 14px;
  font-weight: 500;
}

.titleContainer {
  font-weight: 600;
  color: #104665;
  font-size: 16px;
  padding-left: 2px;
}
