.socialStatistic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px;

  @media all and (max-width: 740px) {
    flex-direction: column;
    margin-top: -50px;
    padding: 15px;
  }
}

.socialStatItem {
  padding: 12px;
  background: var(--transparent-light-white);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex: 0 0 calc((100% / 5) - 30px);
  margin: 15px;

  @media all and (max-width: 740px) {
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}

.iconWrapper {
  background: var(--transparent-light-white);
  box-shadow: inset -6px -6px 14px rgba(0, 0, 0, 0.38);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  position: relative;
  border: 1px solid;

  svg {
    width: 25px;
  }
}

.statTitle {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--white-text-color);
  opacity: .7;
  font-size: 12px;
  display: block;
}

.statCounter {
  font-weight: 800;
  font-size: 24px;
  color: var(--white-text-color);
  line-height: 1;
}

.tooltipIconCont {
  padding: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  margin: auto 0 auto 5px;

  &:hover .tooltipCont {
    display: flex;
  }
}

.tooltipCont {
  display: none;
  position: absolute;
  bottom: calc(100% + 9px);
  left: -137px;
  border: 1px solid #DDE1E3;
  background: var(--white-background-color);
  border-radius: var(--border-radius);
  font-size: 12px;
  padding: 12px;
  width: 275px;
  z-index: 1;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -14px;
    left: 50%;
    border: 7px solid transparent;
    border-top-color: var(--white-background-color);
  }
}

.tooltipInnerIcon {
  margin-right: 9px;

  svg {
    width: 18px;
    height: 18px;
    color: #D9E0E5;
  }
}

.tooltipTextCont {
  overflow: hidden;
}

.statsRow {
  display: flex;

  .tooltipIcon {
    color: #FFFFFF;
    opacity: 0.2;

    &:hover {
      opacity: 0.5;
    }
  }
}