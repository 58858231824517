.item {
  display: flex;
  align-items: center;
}

.itemImg {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}