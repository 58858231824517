.notificationListContainer {
  position: absolute;
  width: 370px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  max-height: 375px;
  background: var(--white-background-color);
  transform: translate(calc(-50% + 42px), 10px);
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  &.client {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);

    .notificationListHeader {
      color: var(--transparent-light-white-4);
    }

    .clearNotifications {
      color: var(--white-text-color);
    }

    .list {
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: padding-box;
        border-radius: 20px;
        background-color: var(--transparent-light-white-4);
      }
    }
  }
}

.list {
  flex: 1;
  padding: 6px 5px 12px 12px;
  max-height: 300px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #27373b transparent !important;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--ligh-darkest-color);
  }
}

.notificationListHeader {
  padding: 10px 15px 5px 10px;
  display: flex;
  justify-content: space-between;
  color: var(--text-primary-light-color);
  font-size: 14px;
}

.clearNotifications {
  background: none;
  text-decoration: underline;
  border: none;
  color: var(--text-primary-color);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.notificationsTitle {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 7px;
    font-size: 0;
    width: 10px;

    svg {
      width: 100%;
    }
  }
}