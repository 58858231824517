.reportContainer {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  background: var(--white-background-color);
  padding: 12px;
  height: 100%;
}

.topLine {
  display: flex;
  font-size: 12px;
  color: var(--main-text-color);
  position: relative;
  justify-content: space-between;
}

.date {
  opacity: 0.7;
  color: var(--text-primary-light-color);
}

.creator {
  color: var(--text-primary-light-color);
  display: flex;
  align-items: center;
}

.creatorName {
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.imageWraper {
  width: 100%;
  height: 140px;
  margin: 6px 0px;
  position: relative;
  user-select: none;
  overflow: hidden;
  background: lightgray;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &:after {
    content: '';
    display: block;
    margin-top: 60%;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img, svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    padding: 10px;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.description {
  font-size: 12px;
  color: var(--text-primary-light-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  white-space: pre-line;
}

.badgesLine {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 15px;
  overflow: hidden;
}

.trendsContainer {
  margin-top: 5px;
  height: 62px;
}

.trendItem {
  color: var(--text-primary-color);
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid var(--light-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:last-child {
    border: none;
  }
}

.status {
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 5px;
  margin-left: auto;
  position: absolute;
  z-index: 1;
  left: 7px;
  top: 7px;

  &.pending {
    background: #FFEFDF;
    color: #FFAD61;
    border: 1px solid #FFAD61;
  }

  &.draft {
    background: #EEF1F3;
    color: #88A3B2;
    border: 1px solid #88A3B2;
  }

  &.approved {
    background: #D4F1E3;
    color: #29B974;
    border: 1px solid #29B974;
  }

  &.declined {
    background: #FFDFDF;
    color: #FF6161;
    border: 1px solid #FF6161;
  }
}

.eventButtons {
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
}
