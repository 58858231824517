.containerGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.itemContainer {
  margin: 15px;
  max-width: calc((100% / 4) - 30px);
  flex: 0 0 calc((100% / 4) - 30px);

  @media all and (max-width: 1070px) {
    max-width: calc((100% / 2) - 30px);
    flex: 0 0 calc((100% / 2) - 30px);
  }

  @media all and (max-width: 900px) {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}