.formRow {
  display: flex;
  align-items: flex-start;
}

.linkButton {
  display: inline-block;
  text-decoration: none;
}

.actions {
  margin: 10px -7px 0 -7px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    margin: 0 7px;
  }
}

.formItem {

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:first-child {
    width: 100%;

    label {
      width: 100%;
    }
  }
}