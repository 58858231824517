.reportHeader {
  margin-bottom: 50px;
}

.backWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: 970px) {
    flex-direction: column;
    margin-bottom: -30px;

    &>a {
      align-self: flex-start;
    }
    &>div {
      align-self: flex-end;
      margin-top: 10px;
    }
  }
}

.dateData {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--transparent-light-white-7);

  .date {
    font-weight: bold;
    color: var(--white-text-color);
    margin-left: 5px;
  }
}

.backIcon {
  transform: rotate(180deg);
}

.backButton {
  padding: 12px 15px;
}

.titleReport {
  font-weight: 800;
  font-size: 72px;
  line-height: 62px;
  margin: 0;
  text-transform: uppercase;
  padding: 0 15px;
  flex: 1;
  overflow-wrap: break-word;
}

.titleReportFull {
  max-width: 100%;
}

.descriptionReport {
  color: var(--white-text-color);
  padding: 0 15px;
  opacity: .7;
  margin: 0;
  flex: 1;
  font-size: 22px;
  line-height: 38px;
  white-space: pre-line;
}

.reportHeadingContent {
  display: flex;
  align-items: flex-start;
  color: var(--white-text-color);
  margin: 45px -15px;

  @media all and (max-width: 970px) {
    flex-direction: column;
  }
}