.toggleButton {
  cursor: pointer;
  color: var(--primary-color);
  display: inline-block;
}

.icon {
  margin-left: 5px;

  &.opened {
    svg {
      transform: rotate(180deg);
    }
  }
}