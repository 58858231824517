.trendsWrapper {
  margin: 25px 0;
}

.trendsTitle {
  color: var(--white-text-color);
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  margin: 10px 0;
}

.trends {
  display: flex;
  flex-wrap: wrap;
  margin: -14px -15px;

  @media all and (max-width: 740px) {
    flex-direction: column;
    width: 100%;
  }
}