.emptyListMessage {
  margin-top: 10px;
  text-align: center;
}

.colorWhite7 {
  color: var(--transparent-light-white-7);
}

.colorWhite8 {
  color: white;
}