.buttonStaff {
  border: 1px solid rgba(16, 70, 101, 0.1);
}

.button {
  margin-top: auto;
  margin-bottom: auto;
  padding: 6px 12px 6px 16.5px;
  height: 32px;
}

.buttonText {
  font-weight: 400;
}
