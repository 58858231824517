@import "scss/mixins/general";

.peoplePage {
  padding: 24px 0;
}

.backButton {
  padding: 12px 15px;
}

.backIcon {
  transform: rotate(180deg);

  svg {
    display: block;
  }
}

.peopleItemHeader {
  margin: 30px 0px;
}

.editButton {
  border: 1px solid rgba(16, 70, 101, 0.1);
  padding: 12px 18px !important;
  margin-left: 4px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding-top: 20px;

  & > div {
    margin-right: 4px;
    margin-top: 4px;
  }
}

.peopleInfoContainer {
  background: white;
  border-radius: 20px;
  display: flex;
  padding: 36px;

  .peopleAvatar {
    width: 210px;
    margin: 0px 10px;
    height: 210px;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    background: #f7f9fa;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 50%;
      height: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .rightBlock {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    width: 100%;
    overflow: hidden;

    .titleLine {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .peopleTitle {
        color: #104665;
        font-weight: 700;
        font-size: 32px;
        padding-right: 12px;
        word-break: break-word;
      }

      .editButtonsPanel {
        display: flex;
      }
    }

    .counterLine {
      display: flex;

      .counterBlock {
        margin-right: 23px;

        div {
          &:first-child {
            color: rgba(16, 70, 101, 0.5);
            font-size: 16px;
          }

          &:last-child {
            color: #104665;
            font-size: 22px;
            font-weight: 700;
            line-height: 1;
          }
        }
      }
    }

    .descriptionLine {
      padding-top: 20px;
      color: rgba(16, 70, 101, 0.5);
      font-size: 14px;
    }
  }

  &.peopleClientContainer {
    background: var(--transparent-light-white);

    .peopleAvatar {
      background: rgba(255, 255, 255, 0.06);
      border: rgba(255, 255, 255, 0.19);
    }

    .titleLine {
      .peopleTitle {
        color: white;
      }
    }

    .descriptionLine {
      color: rgba(255, 255, 255, 0.6);
    }

    .tagsContainer {
      & > div {
        color: white;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .counterLine {
      .counterBlock {
        div {
          &:first-child {
            color: rgba(255, 255, 255, 0.5);
          }

          &:last-child {
            color: white;
          }
        }
      }
    }
  }
}

.tabsBlock {
  margin-top: 56px;

  .tabsButtons {
    display: flex;

    button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }

  .tabsContent {
    margin-top: 16px;
  }

  &.clientTabsBlock {
    button {
      font-size: 18px;
      font-weight: 600;
      border-color: rgba(255, 255, 255, 0.06);
      padding: 12px 32px;
    }
  }
}

.tabsContentContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.itemContainer {
  margin: 15px;
  max-width: calc((100% / 4) - 30px);
  flex: 0 0 calc((100% / 4) - 30px);
}

.socialLinksLine {
  display: flex;
  margin-bottom: 16px;
}

.socialLinkWrap {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 2px rgba(56, 56, 56, 0.45);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1DA1F2;

  svg {
    width: 60%;
    height: 60%;
  }
}
