.badgeContainer {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  border: solid 1px var(--body-color);
  padding: 0 5px;
  margin-right: 4px;

  svg {
    width: 12px;
    display: block;
  }

  div {
    padding-left: 2px;
    font-size: 12px;
    font-weight: 500;
    color: var(--text-primary-color)
  }
}