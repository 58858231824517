.container {
  height: 100%;
  color: var(--text-primary-color);
  font-weight: 500;

  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.rowsCont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.kymIndexCont {
  background: var(--body-color);
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid #DDE1E3;
  display: flex;
  min-width: 70px;
  margin-left: 10px;
  align-items: center;
}

.kymIndexTitle {
  font-size: 12px;
  color: var(--text-primary-light-color);
  text-align: center;
}

.kymIndex {
  color: var(--text-primary-color);
  font-weight: 500;
  text-align: center;
  line-height: 1;
  padding: 5px 0;
  margin-left: 20px;
}

.itemContainer {
  display: inline-flex;
  align-items: center;
  margin: 2px 5px;
  justify-content: flex-end;
  flex: 1;
}

.title {
  padding-right: 8px;
}

.borderedContainer {
  width: 68px;
  display: flex;
  align-items: center;
  border: 1px solid #DDE1E3;
  border-radius: var(--border-radius);
  padding: 3px 8px;
  background: var(--white-background-color);
}

.inputCont {
  overflow: hidden;
  font-size: 14px;

  input {
    outline: none;
    border: none;
    color: var(--text-primary-color);
    font-weight: 500;
  }
}

.tooltipIconCont {
  display: flex;
  margin-left: 2px;
  position: relative;

  &:hover .tooltipCont {
    display: flex;
  }

  svg {
    color: #D9E0E5;

    &:hover {
      color: #7F9CAC;
    }
  }
}

.tooltipCont {
  display: none;
  position: absolute;
  bottom: calc(100% + 9px);
  left: -137px;
  border: 1px solid #DDE1E3;
  background: var(--white-background-color);
  border-radius: var(--border-radius);
  font-size: 12px;
  padding: 12px;
  width: 275px;
  z-index: 1;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -14px;
    left: 50%;
    border: 7px solid transparent;
    border-top-color: var(--white-background-color);
  }
}

.tooltipInnerIcon {
  margin-right: 9px;

  svg {
    width: 21px;
    height: 21px;
  }
}

.tooltipTextCont {
  overflow: hidden;
}