.footer {
  background: var(--footer-bg);
  padding: 20px 0;
  position: relative;
  z-index: 1;

  &.client {
    background: var(--transparent-light-white);
  }

  .footerLinksBlock {
    a {
      color: var(--text-primary-color);
      text-decoration: none;
      margin: 10px;
      font-size: 14px;
    }
  }
}

.title {
  color: var(--text-primary-color);
  font-weight: 800;
  font-size: 24px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;


  @media all and (max-width: 740px) {
    display: flex;
    flex-direction: column;

    svg {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.copyright {
  color: var(--copyright-color);
  font-size: 14px;

  &.client {
    color: var(--white-text-color);
    opacity: .4;
  }
}

.footerClientLinksBlock {
  a {
    color: var(--text-primary-light-color);
    text-decoration: none;
    margin: 10px;
    font-size: 14px;
  }
}