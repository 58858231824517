.fileWrapper {
  padding: 10px 6px;
  background: rgba(243, 245, 247, 0.69);
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
}

.fileExtension {
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: #EEF1F3;
  border-radius: 4px;
  font-size: 12px;
  color: #2B2D33;
  text-transform: uppercase;
  margin-right: 15px;
}

.fileName {
  color: var(--text-primary-color);
  font-size: 14px;
  line-height: 22px;
  padding-right: 30px;
}

.removeIcon {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  color: var(--text-primary-light-color);
}