.row {
  display: flex;
}

.title {
  flex: 1;
  text-align: center;
}

.asideSection {
  flex: 0 0 200px;
  margin: 19px 0;

  .declineButton {
    margin-top: 2px;
    padding: 6px 24px;

    .declineButtonText {
      font-size: 16px;
      line-height: 25.6px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.footerRightContent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.formRowFields {
  flex: 1;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.generalData {
  margin-bottom: 30px;
}

.textEditorWrap {
  margin-bottom: 8px;

  [data-offset-key] {
    color: white;
  }

  img {
    max-width: 100%!important;
  }

  [data-contents="true"] {
    ol, ul {
      padding-inline-start: 20px !important;
    }

    a[href] {
      color: #1CA5E0;
      span, p {
        color: #1CA5E0;
      }
    }
  }

  [for="openLinkInNewWindow"] {
    margin-bottom: 6px;
  }

}

.errorText {
  font-size: 14px;
  color: var(--danger-color);
  padding: 0 12px;
}
