.collectionContainer {
  margin-bottom: 15px;

  .collectionWrap {
    display: flex;

    .imgWrapBlock {
      flex-shrink: 0;
      width: 259px;
      height: 178px;
      border-radius: 12px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -1;
      }

      svg {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 12px;
      }
    }

    .collectionRightBlock {
      padding-left: 37px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .collectionHeader {
        display: flex;
        align-items: flex-start;
        padding-bottom: 18px;

        .collectionTitle {
          font-weight: 700;
          color: white;
          margin-right: 10px;
          font-size: 24px;
          max-width: 80%;
        }

        .collectionTrendsNum {
          font-size: 16px;
          padding-top: 8px;
          padding-left: 8px;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .collectionDescription {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        white-space: pre-wrap;
        word-break: break-all;
      }

      .collectionButtonsBlock {
        display: flex;
        margin: 23px 0 5px 0;
      }
    }
  }

  .editButton {
    margin-right: 5px;
  }

  .shareButton {
    color: #38a5e0 !important;
    margin-right: 5px;
  }

  .deleteButton {
    color: var(--danger-color) !important;
    margin-right: 5px;
  }

  &.adminStyleWrap {
    button {
      background: white;
      border: 1px solid rgba(16, 70, 101, 0.1) !important;
    }

    .deleteButton {
      color: var(--text-primary-color) !important;
    }
    .collectionTitle {
      color: var(--text-primary-color) !important;
    }

    .collectionTrendsNum, .collectionDescription {
      color: rgba(136, 163, 178, 0.84) !important;
    }

    .imgWrapBlock {
      svg {
        border: solid 1px white;
      }
    }
  }

  .sharedInfoBlock {
    border: 1px solid red;
    margin-top: 10px;

    .createdByBlock {
      display: flex;

      .avatarBlock {
        width: 24px;
        height: 24px;
        border: 1px solid orange;
        border-radius: 100px;
        overflow: hidden;h

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .sharedToBlock {
      display: flex;
    }
  }
}

.additionalInfoRow {
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
}
