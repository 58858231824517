.cloud {
  display: flex;
  height: 580px;
  margin-bottom: 10px;
}

.word {
  cursor: pointer;
}

.searchInclude {
  background: rgba(68, 165, 224, 0.38);
  border-radius: 8px;
}

.emptyDataWrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.emptyData {
  font-size: 32px;
  font-weight: 800;
  margin: 40px 0 0 0;
  &.client {
    color: var(--white-text-color);
  }
}

.wordWeight-1 {
  font-size: 16px!important;
  color: #0B2D40;
}

.wordWeight-2 {
  font-size: 20px!important;
  color: #555CFF;
}

.wordWeight-3 {
  font-size: 24px!important;
  color: #384CB6;
}

.wordWeight-4 {
  font-size: 32px!important;
  color: #7ED5AB;
  font-weight: 500;
}

.wordWeight-5 {
  font-size: 56px!important;
  font-weight: 800;
  color: #49B4F4;
}