.mentions {
   & > div {
     background: none!important;
   }
}

.mentions--multiLine {
  width: 100%;

  .mentions__highlighter, .mentions__input  {
    outline: none;
    border: none;
    background: transparent;
    color: var(--white-text-color);
    font-size: 16px!important;
    padding: 0;
    max-height: 76px;
    overflow: auto!important;
    line-height: 20px;
    font-family: sans-serif!important;
    border: none!important;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.mentions__suggestions__list {
  background: var(--dark);
  box-shadow: 0 0 10px var(--dark);
  padding: 15px 10px!important;
  border-radius: 5px;
  max-height: 240px;
  overflow: auto;
  width: 400px;
  max-width: 100%;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: var(--white-text-color);
  border-radius: 5px;
  margin-bottom: 5px;
}

.mentions__suggestions__item--focused {
  background: var(--primary-light-gradient-color);
  color: var(--primary-color);
}

.mention {
  color: var(--primary-color);
  position: relative;
  z-index: 1;
}