:global {
  .react-select-container.isClient {
    .react-select__control {
      background: var(--transparent-light-white-2);
      border-color: transparent!important;
    }

    .react-select__placeholder, .react-select__input {
      color: var(--transparent-light-white-4)!important;
    }

    .react-select__option {
      &--is-focused {
        background: var(--primary-light-gradient-color);
        color: var(--primary-light-color);
      }
    }

    .react-select__multi-value {
      &__label {
        color: var(--primary-light-color)!important;
      }
      background: var(--primary-light-gradient-color)!important;

      &__remove {
        &:hover {
          background-color: transparent;
        }
      }
    }

    .react-select__menu {
      background: var(--dark);
      &-list {
        &::-webkit-scrollbar {
          background: rgba(0, 0, 0, 0.26);
          border-radius: 100px;
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border: 2px solid transparent;
          border-radius: 4px;
          background-color: #27373b;
        }
      }
    }
  }

  .react-select__indicators {
    display: none !important;
  }

  .react-select__multi-value__remove {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.786156 0.250232C0.679805 0.25026 0.575878 0.282014 0.487663 0.341435C0.399447 0.400855 0.330951 0.485242 0.290932 0.583807C0.250913 0.682371 0.241189 0.790635 0.263003 0.894757C0.284817 0.998879 0.337178 1.09413 0.413392 1.16833L3.24305 3.99885L0.413392 6.82938C0.362084 6.87865 0.321121 6.93768 0.292902 7.00299C0.264683 7.0683 0.249775 7.13859 0.249051 7.20973C0.248327 7.28088 0.261803 7.35145 0.288688 7.41732C0.315573 7.4832 0.355327 7.54304 0.405622 7.59335C0.455917 7.64366 0.515742 7.68343 0.581594 7.71032C0.647445 7.73721 0.717999 7.75069 0.789124 7.74997C0.860248 7.74925 0.930514 7.73433 0.995804 7.70611C1.0611 7.67788 1.1201 7.6369 1.16936 7.58558L3.99902 4.75505L6.82868 7.58558C6.87794 7.6369 6.93695 7.67788 7.00224 7.70611C7.06753 7.73434 7.1378 7.74925 7.20892 7.74997C7.28005 7.7507 7.3506 7.73722 7.41645 7.71032C7.4823 7.68343 7.54213 7.64366 7.59242 7.59335C7.64272 7.54304 7.68247 7.4832 7.70936 7.41733C7.73624 7.35145 7.74972 7.28088 7.749 7.20973C7.74827 7.13859 7.73336 7.0683 7.70514 7.00299C7.67692 6.93768 7.63596 6.87865 7.58465 6.82938L4.75499 3.99885L7.58465 1.16833C7.6619 1.09321 7.71467 0.996502 7.73603 0.890874C7.75739 0.785246 7.74636 0.675627 7.70437 0.576381C7.66238 0.477135 7.5914 0.392893 7.50072 0.334694C7.41005 0.276496 7.30391 0.247056 7.19623 0.250232C7.05733 0.254372 6.92551 0.312437 6.82868 0.412126L3.99902 3.24265L1.16936 0.412126C1.11954 0.360898 1.05996 0.320178 0.994144 0.292372C0.928325 0.264565 0.857603 0.250237 0.786156 0.250232V0.250232Z' fill='%2388A3B2'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    cursor: pointer;

    svg {
      display: none;
    }
  }

  .react-select__multi-value__label {
    color: var(--text-primary-color) !important;
    font-weight: 500;
    font-size: 12px !important;
  }

  .react-select__control {
    border-color: var(--body-color) !important;
    border-radius: var(--border-radius) !important;

    &.react-select__control--is-focused {
      box-shadow: none;
    }
  }

  .react-select__placeholder {
    color: var(--text-primary-color) !important;
    opacity: .5;
    font-size: 14px;
    padding-left: 6px;
  }

  .react-select__multi-value {
    background: var(--body-color) !important;
  }

  .tags-footer-description {
    color: var(--text-primary-color);
    opacity: 0.5;
    font-size: 12px;
    font-weight: 500;
    padding: 6px;
  }

  .react-select__value-container {
    padding: 0px 3px !important;
  }
}