.peopleTag {
  border: solid rgba(255, 255, 255, 0.1) 1px;
  border-radius: 50px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  padding: 4px 12px 4px 4px;
  display: flex;
  color: #44A5E0;
  align-items: center;
  cursor: pointer;

  .peopleTitle {
    max-width: 120px;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .peopleAvatar {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.06);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
