.ranges {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.title {
  margin: 0 0 7px 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary-color);
  &.client {
    color: var(--transparent-light-white-9);
  }
}

.colorPlumb {
  border-radius: 2px;
  width: 12px;
  height: 6px;
  margin-right: 8px;
}

.rangeItem {
  display: flex;
  align-items: center;
  padding: 4px 7px;
  border-radius: 4px;
  background-color: var(--transparent-light-white-4);

  &.client {
    background: rgba(18, 30, 37, 0.6);
    color: var(--white-text-color);
    font-size: 14px;
  }

  &:not(:last-child) {
    margin-left: 6px;
  }
}

.wrapperClient {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-radius: 4px;
  max-width: max-content;
  backdrop-filter: blur(54px);
}

@media all and (max-width: 400px) {
  .rangeItem {
    font-size: 12px;
    padding: 4px;
  }
}