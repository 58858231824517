.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.loginPageMain {
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  height: calc(100% - 80px);
  background: var(--body-color);
}

.loginPageContainer {
  max-width: 770px !important;
  width: 100%;

  @media all and (max-width: 740px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.brand {
  font-size: 32px;
  font-weight: 500;
  color: var(--text-primary-color);
}

.content {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  padding-top: 16px;
  padding-bottom: 34px;
  opacity: .5;
  color: var(--text-color);
}

.loginButton {
  width: 270px;
  margin: 0 10px;
  padding: 10px 15px;
}

.checkingRegistrationStyle {
  color: #1CA5E0;
  font-weight: 700;
  font-size: 20px;
  display: none;
}

.checkingRegistrationShow {
  display: block;
}

.loginPageContainerShow {
  display: none;
}

.errorIndicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1CA5E008;
  color: orange;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.errorIndicatorShow {
  visibility: visible;
}

.errorIndicatorInner {
  padding: 16px 0;
}

.loginButtonText {
  padding: 6px 0;
  font-size: 18px;
}

.welcomeLine {
  font-weight: 400;
  font-size: 32px;

  &:after {
    content: ' ';
  }
}

.topLine {
  font-size: 50px;
  text-align: center;
}

.buttonLine {
  display: flex;
  justify-content: center;
}

.titleLine {
  text-align: center;
}
