.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.formRowFields {
  flex: 1;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
}

.textEditorWrap {
  margin-bottom: 8px;
}

.checkboxContainer {
  margin-bottom: 8px;
}
