.badgeContainer {
  display: flex;
  align-items: center;
  background: #ffffff25;
  min-width: 45px;
  border-radius: var(--border-radius);
  padding: 2px 4px;
  margin-right: 4px;

  svg {
    width: 12px;
    display: block;
  }

  div {
    padding-left: 2px;
    font-size: 12px;
    font-weight: 500;
    color: var(--white-text-color)
  }
}

.badgeIcon {
  color: var(--icons-client-color);
}
