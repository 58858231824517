.wrapper {
  margin-bottom: 10px;
}

.title {
  color: var(--text-primary-color);
  font-weight: 600;
  font-size: 16px;

  &.clientTitle {
    color: var(--white-text-color);
  }
}

.actionButtonText {
  font-weight: normal;
}

.actionButton {
  padding: 11px 22px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
}

.sliderWrapper {
  flex: 1;
  margin-right: 50px;
}