.dateContainer {
  padding-top: 18px;
}

.inputWrapper {
  border-radius: var(--border-radius);
  border: 1px solid var(--light-color);
  padding: 3px 10px;
  display: flex;
  font-size: 14px;
  background: var(--white-background-color);
  align-items: center;

  &.disabled {
    .input {
      cursor: not-allowed;
    }
  }

  &.transparent-light-white {
    background: var(--transparent-light-white-2);
    border-color: transparent;

    .inputIcon{
      color: var(--transparent-light-white-2);
    }

    :global {
      .react-datepicker-popper[data-placement^=bottom] {
        & .react-datepicker__triangle {
          &::before, &::after {
            border-bottom-color: var(--datepicker-background-dark);
          }
        }
      }
    }
  }
}

.input {
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  color: var(--text-primary-color);
  line-height: 22px;
  font-size: 14px;
  background: transparent;
  text-align: center;
  height: 36px;

  &.light {
    color: var(--white-text-color);
  }

  &::placeholder {
    color: var(--text-primary-light-color);
    font-size: 14px;
  }
}

.inputIcon {
  color: var(--text-primary-light-color);
  display: flex;
  margin-right: 5px;
}

.days-dark {
  color: var(--datepicker-text-color-dark);

  &:hover {
    background: var(--datepicker-dark-hover);
  }
}

.calendar {
  &.dark {
    background: var(--datepicker-background-dark);
    border-color: var(--datepicker-background-dark);

    :global{
      .react-datepicker__navigation-icon::before {
        border-color: var(--datepicker-dark-arrow-color);
      }
      .react-datepicker__header {
        background: var(--datepicker-background-dark);
        border-bottom: 1px solid var(--transparent-light-white);
      }
      .react-datepicker__day--in-selecting-range {
        background: var(--datepicker-dark-selecting-background);
      }
      .react-datepicker__day--in-range,
      .react-datepicker__day--today {
        background: var(--primary-color);
        color: var(--datepicker-dark-range-text-color);
      }
      .react-datepicker__current-month {
        color: var(--white-text-color);
      }
      .react-datepicker__day-name {
        color: var(--white-text-color);
      }
      .react-datepicker__day--outside-month {
        opacity: 0.4;
      }
    }
  }
}
