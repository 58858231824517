body {
  --body-color: #EEF1F3;
  --main-text-color: #383838;
  --white-text-color: #fff;
  --white-background-color: #fff;
  --primary-color: #1CA5E0;
  --primary-light-color: #1DA1F2;
  --success-color: #29B974;
  --primary-light-gradient-color: linear-gradient(0deg, rgba(29, 161, 242, 0.1), rgba(29, 161, 242, 0.1));
  --primary-gradient: radial-gradient(48.99% 136.9% at 24.64% 136.9%, #1CA5E0 0%, #3439C1 100%);
  --primary-gradient-hover: radial-gradient(68.58% 225.34% at 24.64% 136.9%, #1CA5E0 0%, #3439C1 100%);
  --border-radius: 6px;
  --border-radius-small: 3px;
  --border-radius-large: 12px;
  --secondary-color: #F3F5F7;
  --text-primary-light-color: #88a3b2;
  --text-color: #383838;
  --text-primary-color: #104665;
  --light-color: #e8e8e8;
  --ligh-darkest-color: #DDE4E9;
  --footer-bg: #f9fafb;
  --copyright-color: #9bb2bf;
  --main-background-color: #E5E5E5;
  --danger-color: #FF6161;
  --client-body-color: #000;
  --transparent-light-white-0-5: rgba(255, 255, 255, 0.05);
  --transparent-light-white: rgba(255, 255, 255, 0.1);
  --transparent-light-white-2: rgba(255, 255, 255, 0.2);
  --transparent-light-white-4: rgba(255, 255, 255, 0.4);
  --transparent-light-white-5: rgba(255, 255, 255, 0.5);
  --transparent-light-white-7: rgba(255, 255, 255, 0.7);
  --transparent-light-white-9: rgba(255, 255, 255, 0.9);
  --transparent-light-black: rgba(0, 0, 0, 0.1);
  --transparent-overlay-black: rgba(0, 0, 0, 0.5);
  --dark: #1a1a1a;
  --icons-client-color: #44A5E0;
  --tags-dark-color: #838889;
  --tags-dark-color-2: #303739;
  --modal-dark-background-color: #292929bb;
  --modal-dark-title-color: #6a6a6baa;
  --landing-text-gray-color: #E3E3E3;
  --datepicker-text-color-dark: #C9C8CC;
  --datepicker-background-dark: linear-gradient(215.05deg, #101010 7.63%, #12262F 91.82%);
  --datepicker-background-light: linear-gradient(215.05deg, #F0F0F0 7.63%, #E3E3E3 91.82%);
  --datepicker-dark-hover: #153542;
  --datepicker-dark-selecting-background: #153542;
  --datepicker-dark-range-text-color: #F8F7FA;
  --datepicker-dark-arrow-color: #0084FF;
  --events-background-color: #19252D;
}