.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
}

.col {
  padding: 0 5px;

  img {
    max-width: 100%;
  }
}

.title {
  color: var(--white-text-color);
  font-weight: 700;
  font-size: 100px;
  line-height: 1;
  margin-bottom: 10px;
}

.description {
  color: var(--white-text-color);
  font-weight: 600;
  font-size: 44px;
  line-height: 1;
  margin-bottom: 10px;
}

.soonText {
  font-size: 22px;
  color: #21B9FA;
}

@media all and (max-width: 740px) {
  .col {
    text-align: center;
  }

  .title {
    font-size: 65px;
  }

  .description {
    font-size: 34px;
  }

  .soonText {
    font-size: 20px;
    margin-bottom: 20px;
  }
}