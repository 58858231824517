.searchInput {
  flex: 1;

  label {
    padding: 4px 10px;
  }
}

.backButton {
  padding: 12px 15px;
  margin: 0 363px 0 0;
}

.backIcon {
  transform: rotate(180deg);

  svg {
    display: block;
  }
}
