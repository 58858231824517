@import "scss/mixins/general";

.peoplePage {
  padding: 24px 0;
}

.peopleItemHeader {
  margin: 30px 0px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding-top: 20px;

  & > div {
    margin-right: 4px;
    margin-top: 4px;
  }
}

.peopleInfoContainer {
  display: flex;
  margin-top: 80px;

  .avatarsContainer {
    width: 270px;
    height: 178px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
  }

  .peopleEmptyAvatar {
    width: 270px;
    height: 178px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px white;
    border-radius: 12px;
  }

  .rightBlock {
    display: flex;
    flex-direction: column;
    padding-left: 48px;
    padding-left: 100px;
    width: 100%;
    overflow: hidden;

    .titleLine {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .peopleTitle {
        color: #104665;
        font-weight: 700;
        font-size: 32px;
        padding-right: 12px;
        word-break: break-word;
      }
    }

    .descriptionLine {
      color: #88A3B2;
      font-size: 14px;
    }

    .collectionButtonsBlock {
      display: flex;
      margin-top: 12px;
    }

    .shareButton {
      color: #38a5e0 !important;
      background: white;
      border: 1px solid rgba(16, 70, 101, 0.1);
    }

    .deleteButton, .editButton {
      border: 1px solid rgba(16, 70, 101, 0.1);
      padding: 14px 18px !important;
      margin-left: 4px;
      background: white;
    }

    .shareButton, .deleteButton, .editButton {
      &:hover {
        background: #D9E0E5;
      }
    }

    .additionalInfoRow {
      margin-top: 12px;
      display: flex;
      align-items: flex-start;
    }
  }

  &.peopleClientContainer {
    .peopleAvatar {
      background: rgba(255, 255, 255, 0.06);
      border: rgba(255, 255, 255, 0.19);
    }

    .titleLine {
      .peopleTitle {
        color: white;
      }
    }

    .shareButton {
      display: none;
    }

    .deleteButton, .editButton {
      background: rgba(255, 255, 255, 0.1);
    }

    .deleteButton {
      background: rgba(255, 255, 255, 0.1);
      color: #FF6161;
    }

    .shareButton, .deleteButton, .editButton {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .descriptionLine {
      color: rgba(255, 255, 255, 0.6);
    }

    .tagsContainer {
      & > div {
        color: white;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .peopleEmptyAvatar {
      opacity: .2;
    }

    .counterLine {
      .counterBlock {
        div {
          &:first-child {
            color: rgba(255, 255, 255, 0.5);
          }

          &:last-child {
            color: white;
          }
        }
      }
    }
  }
}

.tabsBlock {
  margin-top: 56px;

  .tabsButtons {
    display: flex;

    button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }

  .tabsContent {
    margin-top: 16px;
  }

  &.clientTabsBlock {
    button {
      font-size: 18px;
      font-weight: 600;
      border-color: rgba(255, 255, 255, 0.06);
      padding: 12px 32px;
    }
  }
}

.scrollContainer {
  margin-top: 30px;
}

.containerGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.itemContainer {
  margin: 15px;
  max-width: calc((100% / 4) - 30px);
  flex: 0 0 calc((100% / 4) - 30px);

  @media all and (max-width: 1070px) {
    max-width: calc((100% / 2) - 30px);
    flex: 0 0 calc((100% / 2) - 30px);
  }

  @media all and (max-width: 900px) {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}



