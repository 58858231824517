.formRow {
  display: flex;
  align-items: center;
}

.companyNameField {
  flex: 1;
}

.userLimitField {
  flex: 0 0 100px;
  margin-left: 10px;
}