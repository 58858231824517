.overlay {
  position: absolute;
  z-index: 998;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &.dark {
    background: var(--transparent-overlay-black);
  }
}

.popupLayout {
  position: fixed;
  width: 100%;
  height: calc(100% - 73px);
  left: 0;
  top: 73px;
  z-index: 999;
}

.popup {
  position: relative;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 999;
  min-width: 400px;
  background: var(--white-background-color);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius);

  &.dark {
    background: var(--modal-dark-background-color);

    .popupHeader {
      background: var(--modal-dark-title-color);
    }

    .popupTitle, .closeButton {
      color: white;
    }
  }
}

.closeButton {
  color: var(--text-primary-light-color);
  outline: none;
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}

.popupHeader {
  background: var(--light-color);
  text-align: center;
  padding: 12px 10px;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  position: relative;
}

.popupBody {
  padding: 20px 30px;
  min-height: 80px;
}

.popupTitle {
  font-size: 18px;
  color: var(--text-primary-color);
  font-weight: 700;
  line-height: 1;
  max-width: calc(100% - 30px);
}

.popupFooter {
  padding: 0 30px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .popupAction {
    min-width: 90px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.popupScrollFooter {
  padding: 16px 0 8px 0;
}

.scrollBody {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba(160, 172, 183, 0.6);
  }
}

@media all and (max-height: 900px) {
  .scrollBody {
    max-height: 630px;
  }
}

@media all and (max-height: 820px) {
  .scrollBody {
    max-height: 490px !important;
  }
}

