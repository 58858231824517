.attachedImgContainer {
  width: 54px;
  height: 54px;
  position: relative;

  .statusWrapper {
    visibility: hidden;
    width: 20px;
    height: 20px;
    border-radius: 1000px;
    position: absolute;
    top: -5px;
    right: -5px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #ffffffd1;
    background: #00000063;
    padding: 7px;
    cursor: pointer;
  }

  .imgWrapper {
    width: 54px;
    height: 54px;
    border-radius: 8px;
    opacity: .99;
    position: relative;
    background: transparent;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    .statusWrapper {
      visibility: visible;
    }
  }

  .spinnerWrapper {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
