.imgUploaderContainer {
  width: 100%;
}

.imgPreviewContainer {
  width: 122px;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  margin-bottom: 8px;
  user-select: none;

  img {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    display: block;
    margin-top: 52%;
  }
}

.imgUploaderInputContainer {
  display: flex;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  height: 32px;
}

.inputContainer {
  position: relative;
  width: 100%;
  background: var(--white-background-color);
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.input {
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  position: absolute;
}

.buttonContainer {
  height: 100%;
  position: relative;
  flex-shrink: 0;
  height: 32px;
}

.label {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.buttonUploadStyle {
  position: relative;
  border-radius: 0;
  height: 100%;
  padding: 0 29px;
}

.buttonUploadTextStyle {
  font-weight: 500;
  font-size: 14px;
}

.imageTagCont {
  display: flex;
  align-items: center;
  color: var(--text-primary-color);
  opacity: .5;
  padding: 0 4px;

  span {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.closeIconCont {
  padding-left: 6px;
  cursor: pointer;
}

.imageTitle {
  padding-left: 12px;
  font-weight: 500;
  color: var(--text-primary-color);
  font-size: 16px;
}
