.emojiCommentContainer {
  position: relative;
}

.emojiPickerContainer {
  position: absolute !important;
  width: 350px;
  height: 450px;
  top: 100%;
  right: 0;
  z-index: 1;
}