.header {
  padding: 18px 0;
  border-bottom: 1px solid var(--light-color);
  position: fixed;
  width: 100%;
  z-index: 999;
  background: var(--white-background-color);

  &.client {
    padding: 15px 0;
    background: none;
    border-bottom-color: var(--transparent-light-black);

    &:before {
      content: "";
      position: absolute;
      background: var(--transparent-light-black);
      backdrop-filter: blur(20px);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

}

.title {
  color: var(--text-primary-color);
  margin: 0;
  font-weight: 800;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 740px) {
    flex-wrap: wrap;

    svg {
      flex-shrink: 0;
    }
  }

}

.actions {
  display: flex;
  align-items: center;

  .action {
    font-weight: 500;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .linkAction {
    a {
      display: block;
    }
  }

  @media all and (max-width: 740px) {
    width: 100%;
    flex-shrink: 0;
    margin-top: 16px;
    justify-content: flex-end;

    .action {
      button {
        background: var(--transparent-light-white);
        padding: 12px;
        svg {
          width: 16px;
          height: 16px;
        }
      }

      div:nth-child(2) {
        display: none;
      }
    }
  }
}

.burgerMenu {
  background-color: var(--white-background-color);
  border: 1px solid var(--transparent-light-white);
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: var(--border-radius);
  min-width: 132px;
  margin-top: 8px;
  display: none;
  position: absolute;
  padding: 8px;

  &.clientMenu {
    background: #1a1b1c;

    .menuLink {
      color: white;
    }
  }

  &.active {
    display: block;
  }
}

.actionButton {
  padding: 11px 17px;
}

.wordCloudbutton {
  width: 42px!important;
  height: 42px!important;
}

.actionButtonText {
  font-weight: normal;
}

.logOut {
  margin-right: 24px;
  font-weight: 500;
  opacity: .5;
  font-size: 14px;
  color: var(--text-primary-color);
  cursor: pointer;

  @media all and (max-width: 740px) {
    margin-right: auto;
  }
}

.menuLink {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-primary-color);
  padding: 5px 8px;
  border-radius: 2px;
  margin-bottom: 2px;

  &.active, &:hover {
    color: var(--primary-color);
    background: linear-gradient(0deg, rgba(29, 161, 242, 0.1), rgba(29, 161, 242, 0.1));
  }
}
