body {
  @media all and (min-width: 900px) {
    scrollbar-color: #334248 transparent !important;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background: #000;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 20px;
      background-color: #334248;
    }
  }
}

.landingPageContainer {
  width: 100%;
  flex-direction: column;
  position: relative;
  background: #01151F;
  overflow-x: hidden;
}

.introducingContainerWrap {
  background: url("/img/landing-background-tile.png") repeat-x center;
}

.headingRow {
  justify-content: space-between;

  a {
    font-size: 14px;
    color: var(--white-text-color);
  }
}

.landingPageRow {
  width: 80%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  @media all and (max-width: 1600px) {
    max-width: 100%;
    width: 100%;
    padding-left: 34px;
    padding-right: 34px;
  }
}

.landingPageHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 83px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  display: flex;
  justify-content: center;
  padding: 17px 50px;
  z-index: 2;

  @media all and (max-width: 770px) {
    padding-left: 0
  }
}

.introducingContainer {
  display: flex;
  justify-content: center;
  padding-top: 248px;
  padding-bottom: 180px;
  background: url("/img/landing-background.png") no-repeat center;

  .landingPageRow {
    position: relative;

    .scrollToNextButton {
      position: absolute;

      width: 64px;
      height: 64px;
      border-radius: 100px;
      background: rgba(28, 165, 224, 0.04);
      bottom: -64px;
      right: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #C2C5FF;
      cursor: pointer;

      svg {
        display: block;
        transform: rotate(180deg);
        width: 12px;
        height: 12px;

        path {
          stroke-width: 3;
        }
      }
    }

    .scrollToNextButton:hover {
      background: rgba(28, 165, 224, 0.22);
    }
  }

  .landingTitleMobileWrap {
    display: none;
  }

  .landingMobileImg {
    display: none;
  }

  .introducingLeftColumn {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .landingPageDesc {
    text-transform: uppercase;
    color: var(--white-text-color);
    font-weight: 700;
    white-space: pre-wrap;
    min-width: 590px;
    width: 100%;
    line-height: 1;
    font-size: 64px;
    margin: 0;
  }

  .landingPageText {
    font-size: 18px;
    color: var(--landing-text-gray-color);
    font-weight: 500;
    margin: 0;
    margin-top: 100px;
  }

  .introducingButtonWrapper {
    margin-top: 70px;
  }

  @media all and (max-height: 1024px) {
    min-height: 100vh;
  }

  @media all and (max-width: 770px) {
    padding-top: 160px;
    padding-bottom: 100px;

    .scrollToNextButton {
      right: 10% !important;
      width: 50px !important;
      height: 50px !important;
      bottom: -50px !important;
    }

    .landingPageDesc {
      font-size: 50px;
    }
    .landingPageText {
      margin-top: 50px;
    }
  }

  @media all and (max-width: 690px) {
    padding-top: 0;
    background: #021117;

    .scrollToNextButton {
      right: calc(50% - 20px) !important;
      bottom: -64px !important;
      width: 40px !important;
      height: 40px !important;
    }

    .landingPageDesc {
      display: none;
      z-index: 1;
    }
    .landingPageText {
      margin-top: -50px !important;
      font-size: 14px;
      line-height: 135%;
      z-index: 1;
      text-align: center;
    }

    .introducingLeftColumn {
      width: 100%;
    }

    .landingTitleMobileWrap {
      display: flex;
      width: calc(100% + 68px);
      margin-left: -34px;
      margin-bottom: -20px;
      padding: 76px 34px;
      padding-bottom: 0;
      background: #0c6697;
      flex-wrap: wrap;
      position: relative;

      .landingPageDesc {
        display: flex;
        font-size: 26px;
        align-self: center;
      }

      .landingBadge {
        width: 26px;
        height: 5px;
        position: absolute;
        bottom: -8px;
        z-index: 1;
      }
    }

    .landingPageText {
      margin-top: 0;
    }

    .introducingButtonWrapper {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }

    .landingMobileImg {
      position: relative;
      display: block;
      width: calc(100% + 68px);
      margin-left: -34px;
      overflow: hidden;

      img {
        left: 0;
        top: 0;
        width: 100%;
        position: absolute;
      }
    }

    .landingMobileImg:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      margin-top: 67%;
    }
  }
}

.landingBadge {
  margin-top: 23px;
  width: 48px;
  height: 18px;
  border-radius: 100px;
  background: linear-gradient(to right, #007DC8, #0996BD);
}

.mainPartContainer {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  background: #01151F;

  .landingPageRow {
    justify-content: space-between;
  }

  .mainPartContainerColumn {
    width: 21%;
    display: flex;
    justify-content: center;
    background: #01151F;

    .mainPartCartWrapper {
      display: flex;
      flex-direction: column;
      max-width: 280px;

      .landingBadge {
        width: 26px;
        height: 5px;
        margin-top: 4px;
      }

      svg {
        display: block;
        align-self: center;
        width: 60%;

      }

      .mainPartTitle {
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 600;
        line-height: 1;
        white-space: pre-wrap;
        margin-top: 40px;

        background: linear-gradient(to right, #9CDAFF, #4DD4DD, #A3D8A3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .mainPartDescription {
        font-size: 16px;
        margin-top: 25px;
        color: var(--landing-text-gray-color);
      }
    }
  }

  .sliderLeftButton, .sliderRightButton {
    display: none;
  }

  @media all and (max-width: 990px) {
    .mainPartContainerColumn {
      width: 50%;
    }
  }

  @media all and (max-width: 770px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media all and (max-width: 690px) {
    padding: 0;
    overflow: hidden;
    position: relative;

    .sliderLeftButton, .sliderRightButton {
      display: flex;
      position: absolute;
      padding: 10px;
      background: rgba(28, 165, 224, 0.04);
      border-radius: 100px;
      width: 40px;
      height: 40px;
      top: calc(50% - 25px);
      z-index: 1;
      opacity: .7;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .sliderLeftButton:active, .sliderRightButton:active {
      background: rgba(28, 165, 224, 0.22);
    }

    .sliderLeftButton {
      left: 0;
    }

    .sliderRightButton {
      right: 0;
    }

    .sliderHidden {
      display: none !important;
    }

    .landingPageRow {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      transition-property: transform;
      transition-duration: 600ms;
    }

    .mainPartContainerColumn {
      width: 100vw;
      flex-shrink: 0;

      .mainPartCartWrapper {
        margin: 20px;

        .mainPartDescription {
          font-size: 14px;
          line-height: 135%;
        }

        .mainPartTitle {
          margin-top: 0;
        }
      }
    }
  }
}

.landingBlockTitle {
  font-size: 64px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  background: linear-gradient(to right, #9CDAFF, #4DD4DD, #A3D8A3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aboutPartContainer {
  display: flex;
  justify-content: center;
  padding: 116px 0;
  background: #020A0E;

  .aboutSidePart {
    width: 50%;
    padding-right: 110px;

    .aboutDescription {
      color: var(--landing-text-gray-color);
      font-size: 16px;
      padding-top: 38px;
    }

    .aboutImgWrap {
      width: 100%;
    }
  }

  @media all and (max-width: 990px) {
    .aboutSidePart:nth-child(1) {
      order: 2;
    }

    .aboutSidePart {
      width: 100%;
      padding: 30px;
    }
  }

  @media all and (max-width: 770px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media all and (max-width: 690px) {
    .landingPageRow {
      padding: 0;
    }

    .landingBadge {
      width: 26px;
      height: 5px;
      margin-top: 4px;
    }

    .landingBlockTitle {
      font-size: 40px;
    }

    .aboutSidePart:nth-child(1) {
      padding: 0;
    }

    .aboutDescription {
      padding-top: 12px !important;
      font-size: 14px;
      line-height: 130%;
    }
  }
}

.productsPartContainer {
  display: flex;
  justify-content: center;
  background: #01151F;
  padding: 96px 0;

  .productsSidePart {
    width: 50%;
    padding-left: 110px;

    .productsImgWrapper {
      width: 80%;
      padding-top: 200px;

      img {
        width: 100%;
      }
    }

    & > .landingBlockTitle {
      font-size: 64px;
      font-weight: 700;
    }

    .landingBadge {
      margin-bottom: 100px;
    }

    .productsItem {
      display: flex;
      align-items: center;
      padding-bottom: 30px;

      .landingBlockTitle {
        font-size: 26px;
        padding-left: 27px;
      }
    }
  }

  @media all and (max-width: 990px) {
    .productsSidePart {
      width: 100%;
    }
  }

  @media all and (max-width: 770px) {
    .productsImgWrapper {
      width: 50% !important;
      padding-top: 40px !important;
    }
  }

  @media all and (max-width: 690px) {
    padding: 50px 0;

    .landingBlockTitle {
      font-size: 40px !important;
    }

    .productsSidePart {
      padding: 0;
    }

    .landingBadge {
      width: 26px;
      height: 5px;
      margin-top: 4px;
      margin-bottom: 50px !important;
    }

    .productsSidePart:nth-child(2) {
      justify-content: center;
      display: flex;
    }

    .productsItem {
      .landingBlockTitle {
        font-size: 20px !important;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }
}

.morePartContainer {
  padding: 112px 0;
  background: #095579;
  display: flex;
  justify-content: center;

  .landingPageRow {
    padding: 0 150px;
    justify-content: space-between;
  }

  .moreRightPart {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    button {
      margin-top: 70px;
    }
  }

  .moreIconMobileWrap {
    display: none;
  }

  @media all and (max-width: 770px) {
    padding: 70px 0;

    .landingBlockTitle {
      font-size: 50px;
    }
  }

  @media all and (max-width: 690px) {
    padding: 50px 34px;

    .landingPageRow {
      padding: 0;
      flex-direction: column;

      .moreIconWrap {
        display: none;
      }

      .moreRightPart {
        flex-direction: column;
        align-items: center;

        .landingBadge {
          width: 26px;
          height: 5px;
          margin-top: 4px;
          align-self: flex-start;
          margin-bottom: 20px;
        }

        .landingBlockTitle {
          font-size: 40px;
          align-self: flex-start;
        }

        .moreIconMobileWrap {
          display: block;
          width: 50%;

          svg {
            width: 100%;
          }
        }
      }

        button {
          margin-top: 20px;
        }
      }
  }
}

.landingPageFooter {
  background: #020A0E;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 50px;
  color: #fff7;

  .landingPageRow {
    justify-content: space-between;

    .landingFooterLinks {

      a {
        color: white;
        text-decoration: none;
        margin: 0 20px;
        font-size: 13px;
      }
    }
  }

  @media all and (max-width: 1190px) {
    .footerCopyright {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }

    .landingPageRow {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media all and (max-width: 750px) {
    .landingPageRow {
      flex-direction: column;
      align-items: center;

      .landingFooterLinks {
        margin-top: 30px;
      }
    }
  }

  @media all and (max-width: 550px) {
    .landingFooterLinks {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        font-size: 16px !important;
      }
    }

    .footerCopyright {
      font-size: 14px;
      line-height: 130%;
    }
  }
}
