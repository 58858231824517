.errorWrapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 175px);
  padding: 30px 0;
  position: relative;
  z-index: 2;
}

.errorContainer {
  display: inline-block;
  flex-direction: column;
}

.errorName {
  font-size: 150px;
  font-weight: 800;
  color: var(--primary-color);
  user-select: none;
  letter-spacing: 3px;
  display: flex;
}

.errorDescr {
  font-weight: 300;
  font-size: 32px;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.backToMainLink {
  border-radius: 1000px !important;
  width: 100%;
  padding: 20px 0 !important;
}
