.trendContainer {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  background: var(--white-background-color);
  padding: 12px;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.topLine {
  display: flex;
  font-size: 12px;
  color: var(--main-text-color);
  position: relative;
  justify-content: space-between;
}

.date {
  opacity: 0.7;
  color: var(--text-primary-light-color);
}

.creator {
  color: var(--text-primary-light-color);
  display: flex;
  align-items: center;
}

.creatorName {
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.imageWraper {
  width: 100%;
  height: 140px;
  margin: 6px 0px;
  position: relative;
  user-select: none;
  overflow: hidden;
  background: lightgray;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    margin-top: 60%;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img, svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    padding: 10px;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.description {
  font-size: 12px;
  color: var(--text-primary-light-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 80px;
  white-space: pre-line;
  margin-top: 10px;

  & p {
    margin: 0;
  }

  a {
    color: var(--primary-light-color);
  }
}

.badgesLine {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 15px;
  overflow: hidden;
}

.trendsContainer {
  margin-top: 5px;
  height: 62px;
}

.trendItem {
  color: var(--text-primary-color);
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid var(--light-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:last-child {
    border: none;
  }
}

.status {
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 5px;
  margin-left: auto;
  position: absolute;
  z-index: 1;
  left: 7px;
  top: 7px;

  &.pending {
    background: #FFEFDF;
    color: #FFAD61;
    border: 1px solid #FFAD61;
  }

  &.draft {
    background: #EEF1F3;
    color: #88A3B2;
    border: 1px solid #88A3B2;
  }

  &.approved {
    background: #D4F1E3;
    color: #29B974;
    border: 1px solid #29B974;
  }

  &.declined {
    background: #FFDFDF;
    color: #FF6161;
    border: 1px solid #FF6161;
  }
}

.eventButtons {
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
}

.emojiWrapper {
  font-size: 14px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #a0acb7;
  }
}

.tagsWrapper {
  display: flex;
  overflow: hidden;
  margin-top: 5px;
  position: relative;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #a0acb7;
  }
}

.fadeBlock {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  box-shadow: 0 0 10px 9px white;
}

.tagItem {
  margin-right: 5px;
  font-size: 13px;
  color: var(--primary-color);
  cursor: pointer;
  flex-shrink: 0;
}

.emojiTag {
  padding-right: 2px;
  font-weight: 700;
  color: #efa200;
  flex-shrink: 0;
}

.emojiTagWrap {
  background: rgba(216, 216, 224, 0.44);
  margin: 0 1px;
  border-radius: 4px;
  padding: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
}

.viewReportButtonContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  a {
    width: 100%;

    button {
      width: 100%;
      border-radius: initial;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.deleteButton{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
  background: var(--transparent-light-white-2);
  border-color: var(--transparent-light-black);
}
