.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.linksFormRow {
  align-items: flex-start;
}

.selectRow {
  width: 100%;
  max-width: 50%;
  padding: 0 6px;
}

.selectTitle {
  font-size: 16px;
  color: var(--text-primary-color);
  font-weight: 500;
  padding-left: 6px;
}

.formRowFields {
  flex: 1;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
}

.formField {
  padding: 0 6px;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 10px;
}

.trendForm {
  margin: 18px 0;
  position: relative;
}

.removeTrend {
  color: var(--text-primary-light-color);
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 0;
  cursor: pointer;
}

.generalTrendData {
  margin-bottom: 30px;
}

.addLinkWrapper {
  border: 1px dashed #D9E0E5;
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.addLinkText {
  color: var(--primary-color);
  font-weight: bold;
}

.indexesFormRow {
  align-items: flex-end;
  padding-top: 12px;
}

.tagsFormRow {
  padding-top: 20px;
}

.blockTitle {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}