.errorMessage {
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: var(--danger-color);
}

.message {
  margin-left: 10px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}