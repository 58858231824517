.contactUsContainer {
  padding-bottom: 60px;
}

.pageHeader {
  font-size: 36px;
  margin-top: 102px;
  margin-bottom: 48px;
  color: var(--white-text-color);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.contactUsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  label {
    border-radius: var(--border-radius) !important;
  }

  .contactUsLeftCol {
    justify-content: flex-end;
    color: var(--white-text-color);
    padding-right: 30px;
  }

  .contactUsRightCol {
    flex-direction: column;
    color: rgba(255, 255, 255, 0.7);

    .textCol {
      white-space: pre-wrap;
    }
  }

  .messageBlock {
    height: 200px;

    label {
      padding-right: 3px !important;
    }
  }

  .contactUsCol {
    color: rgba(255, 255, 255, 0.7);
    padding-right: 66px;
    width: 38% !important;
    flex-direction: column;

    label {
      width: 100%;
      background: rgba(255, 255, 255, 0.1);
      padding: 10px 24px;
      max-height: 240px;

      input, textarea {
        color: var(--white-text-color);
      }
    }

  }

  .contactUsCol, .contactUsRightCol, .contactUsLeftCol {
    flex-shrink: 0;
    width: 31%;
    display: flex;
  }
}

.sendMessageRow {
  display: flex;
  justify-content: center;

  .buttonWrap {
    width: 38%;
    align-self: center;
  }
}

.actionButtonText {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 32px;
}

.actionButton {
  padding: 8px 48px;
}


