.containerGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.itemContainer {
  margin: 15px;
  max-width: calc((100% / 4) - 30px);
  flex: 0 0 calc((100% / 4) - 30px);
}

.chartContainer {
  margin-left: -26px;
  margin-bottom: 70px;
}

.reportsWrapper {
  display: flex;
  flex-wrap: wrap;

  .mobileFilters {
    display: none;
  }
}

.filters {
  flex: 0 0 270px;
  margin: 0 30px 0 0;
}

.reportsContent {
  flex: 1;
  max-width: calc(100%);
}

.filtersTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
}

.filtersTitle {
  font-size: 16px;
  margin: auto 0;
}

.filtersClient {
  color: var(--white-text-color);
}

.filtersTopPanel {
  display: flex;
  justify-content: space-between;
}

.filtersTopTitle {
  font-size: 24px;
  font-weight: 700;
}

.collectionItemWrapper {
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);

  .collectionItemHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 93px;

    .infoBlock {
      display: flex;
      flex-direction: column;
      width: calc(100% - 32px);
      padding: 10px;

      .collectionItemHeaderTitle {
        color: white;
        font-weight: 700;
        font-size: 18px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .collectionItemHeaderTotal {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3);
        font-weight: 500;
      }
    }

    .optionsBlock {
      color: white;
      flex-shrink: 0;

      button:first-child {
        margin-bottom: 10px;
      }

      .deleteButton{
        cursor: pointer;
        color: rgba(255, 255, 255, 0.9);
        background: var(--transparent-light-white-2);
        border-color: var(--transparent-light-black);
      }
    }
  }

  .imgContainer {
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background: white;

    &.clientView {
      background: rgba(63, 75, 84, 0.2);
    }

    .imgWrap {
      position: absolute;
      width: auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 39.47%);
      right: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;

      &.adminView {
        border-radius: 6px;
        right: 10px;
        left: 10px;
        background: transparent;

        svg {
          width: 100%;
          height: calc(100% - 2px);
          border: solid 1px #EEF1F3;
          border-radius: 8px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -1;
      }
    }

    &:after {
      content: "";
      display: block;
      margin-top: 62%;
    }

    .descriptionBlock {
      display: none;
    }

    &:hover {
      .descriptionBlock {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.51);
        display: flex;
        align-items: flex-end;

        .descriptionText{
          width: 100%;
          height: 64px;
          margin-bottom: 8px;
          padding: 0 11px;
          color: rgba(255, 255, 255, 0.7);
          font-size: 12px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
  }

  &.adminStyleWrap {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    .collectionItemHeader {
      background: white;

      .collectionItemHeaderTitle {
        color: var(--text-primary-color);
      }

      .collectionItemHeaderTotal {
        color: var(--text-primary-light-color);
      }
    }
  }
}

.deleteButtonAdmin{
  cursor: pointer;
  color: var(--text-primary-color);
  background: var(--body-color);
  border-color: var(--transparent-light-black);
}

.adminWrapper {
  background: white;
}

.adminItem {
  display: flex;
  padding: 13px;

  &:first-child {
    padding: 25px 13px 11px 13px;
  }

  &:last-child {
    padding: 0 13px 22px 13px;
  }
}

.createdLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-primary-light-color);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.userAvatarWrapper {
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  font-size: 0;
  outline: 2px solid var(--white-text-color);
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  flex: none;
  margin: 0 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.userEmail {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: var(--text-primary-color);
  order: 1;
  flex-grow: 0;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: inline;
  text-overflow: ellipsis;
}

.sharedCompanyWrapper {
  display: flex;
  margin-left: 6px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;

  &.withNewRow {
    overflow: visible;
    flex-wrap: wrap;
  }

  &.limitCompanies::after {
    content: '...';
    background: linear-gradient(90deg, transparent, white, white);
    position: absolute;
    right: 0;
    padding-left: 15px;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-primary-color);
  }
}

.sharedCompany {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 6px;
  margin: 2px 0 2px 5px;
  background: rgba(221, 228, 233, 0.4);
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: justify;
  color: var(--text-primary-color);
  white-space: nowrap;
}
