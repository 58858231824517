.containerGrid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.itemContainer {
  margin: 15px;
  max-width: calc((100% / 3) - 30px);
  flex: 0 0 calc((100% / 3) - 30px);

  @media all and (max-width: 1070px) {
    max-width: calc((100% / 2) - 30px);
    flex: 0 0 calc((100% / 2) - 30px);
  }

  @media all and (max-width: 900px) {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

.chartContainer {
  margin-left: -26px;
  margin-bottom: 70px;
}

.wordCloudWrapper {
  display: flex;
  flex-wrap: wrap;

  .mobileFilters {
    display: none;
  }

  @media all and (max-width: 740px) {
    .mobileFilters {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      margin: 0;

      &>div {
        width: 100%;
        margin: 0;
        font-size: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .filtersTitleContainer {
        margin-top: 30px;
        justify-content: flex-end;
      }
    }
  }
}

.filters {
  flex: 0 0 270px;
  margin: 0 30px 0 0;

  @media all and (max-width: 740px) {
    display: none;
  }
}

.wordCloudContent {
  display: flex;
  flex: 1;
  max-width: calc(100% - 300px);
  flex-direction: column;
  min-height: 500px;

  @media all and (max-width: 740px) {
    max-width: 100%;
  }
}

.loaderWrapper {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filtersTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
}

.filtersTitle {
  font-size: 16px;
  margin: auto 0;
}

.filtersClient {
  color: var(--white-text-color);
}

.filtersTopPanel {
  display: flex;
  justify-content: space-between;
}

.filtersTopTitle {
  font-size: 24px;
  font-weight: 700;
}
