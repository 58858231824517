.usersCount {
  font-weight: 700;
  line-height: 24px;
  padding: 0 10px;
  color: var(--text-primary-color);
  border-radius: 20px;
  border: 1px solid var(--text-primary-color);
  font-size: 14px;

  &.dark {
    background: var(--transparent-light-white);
    color: var(--white-text-color);
    border-color: transparent;
  }

  &.limitUsers {
    border-color: var(--danger-color);
    color: var(--danger-color);
  }
}