.socialContainer {
  background: #EEF1F3A8;
  width: 466px;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0px;

  .socialTitle {
    color: #104665;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .socialFields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label:focus-within {
      border-color: #1CA5E0;

      svg {
        color: #1CA5E0;
      }
    }

    .socialField {
      width: calc(50% - 5px);
      margin-top: 10px;

      input {
        font-size: 12px;

        &::placeholder {
          font-size: 12px;
        }
      }

      svg {
        color: #BACAD2;
        width: 16px;
        height: 16px;
      }

      &.filledField {
        svg {
          color: #1CA5E0;
        }
      }
    }
  }
}
