@import "scss/mixins/general";

.content {
  flex: 1;
  position: relative;
  background: var(--body-color);
  padding-top: 79px;
  padding-bottom: 30px;

  &.darkBackground {
    background: #214760;
  }

  &.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (max-width: 740px) {
    padding-top: 135px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  &.client, &.power-client {
    @include gradient-layout;

    .content {
      background: none;
    }
  }

  @media all and (max-width: 740px) {
    overflow: hidden;
  }
}