.filterContainer {
  font-size: 14px;
}

.numbersLine {
  display: flex;
  justify-content: space-between;
}

.nameLine {
  font-size: 14px;
  color: var(--main-text-color);
}

.numbersMinValue, .numbersMaxValue {
  background: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--secondary-color);
  padding: 2px 8px;
  font-size: 14px;
  color: var(--main-text-color);
}

.notShortWidth {
  min-width: 82px;
}

.valueClient {
  color: var(--white-text-color);
  background-color: var(--transparent-light-white-2);
  border: 1px solid var(--transparent-light-black);
}

.trackLine {
  height: 2px;
  width: 100%;
  align-self: center;
}

.thumbItem {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
}

.trackItem {
  height: 30px;
  display: flex;
  width: 100%;
  padding: 0 6px;
}
