.collapseContainer {
  margin-top: 24px;
  padding: 0;
  background: var(--white-background-color);
  border-radius: var(--border-radius);
}

.collapseContainerClient {
  background-color: var(--transparent-light-white);
}

.kymIndexesTitle {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  padding-left: 20px;
  cursor: pointer;
}

.kymTitle {
  color: var(--text-color);
  font-weight: 800;
  display: flex;
  align-items: center;
}

.kymTitleClient {
  color: var(--white-text-color);
}

.collapseButton {
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &.collapsed {
    transform: rotate(180deg);
  }
}

.collapseInnerContainer {
  max-height: 3000px;
  padding: 0 18px 18px 18px;

  &.collapsed {
    max-height: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--transparent-light-white);
}

.transparent {
  background: transparent;
}
