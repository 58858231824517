.emptyListCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

.doneButtonStyle {
  padding-left: 44px;
  padding-right: 44px;
}

.listWrapper {
  padding: 10px;
  overflow-y: auto;
  height: 400px;
  width: 644px;

  .clientListWrap {
    h3 {
      color: rgba(255, 255, 255, 0.49);
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba(206, 206, 206, 0.47);
  }
}

.collectionsListWrap {
  cursor: pointer;

  &.emptyListStyle {
    cursor: initial;
  }
}

.collectionsItemWrap {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  opacity: .9;
  height: 84px;

  &.chosenItem {
    opacity: 1 !important;
    outline: solid 1px #1CA5E0;

    .infoBlock>div:first-child {
      color: #1CA5E0
    }
  }

  &:hover {
    opacity: 1;
  }

  .collectionsItemImg {
    width: 100px;
    height: 60px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      z-index: -1;
    }
  }

  .infoBlock {
    color: white;
    font-size: 12px;
    margin-left: 12px;

    div:first-child {
      font-size: 14px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &.adminStyleWrap {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    .infoBlock {
      color: var(--text-primary-color)
    }
  }
}

.createNewButtonStyle {
  background: transparent !important;
}

.buttonsBlock {
  display: flex;
  padding-top: 15px;

  button {
    width: initial !important;
    margin-right: 20px;
  }

  button:first-child {
    margin-left: auto;
  }
}

.adminAddCollection {
  color: var(--text-primary-color);
}

.searchInput {
  padding: 0 10px;
}
