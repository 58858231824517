.notificationItemContainer {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;

  &:hover {
    background: rgba(255, 255, 255, 0.09);
  }

  &.unread {
    background: #E9F6FE;
    border-radius: 4px;
  }

  &.unreadClient {
    background: var(--transparent-light-white-2);
  }
}

.notificationContent {
  flex: 1;
  display: flex;
  align-items: center;
}

.notificationDate {
  color: var(--text-primary-light-color);
  font-size: 12px;
  text-align: right;
  line-height: 16px;
}

.notificationItem {
  line-height: 1;

  &:not(:last-child) {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--secondary-color);
  }

  &:first-child {
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid var(--secondary-color);
  }

  &.client {
    border-color: rgba(255, 255, 255, 0.05);

    .notificationItemTitle {
      color: var(--white-text-color);
    }

    .description, .notificationDate {
      color: var(--transparent-light-white-4);
    }
  }

  &.REPORT_REJECTED {
    .notificationItemIcon {
      background: rgba(255, 97, 97, 0.2);
    }
  }

  &.REPORT_PUBLISHED {
    .notificationItemIcon {
      background: rgba(29, 161, 242, 0.2);
    }
  }
}

.notificationItemIcon {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.notificationItemTitle {
  font-weight: 500;
  line-height: 1;
  color: var(--text-primary-color);
  display: inline-flex;
  margin-bottom: 5px;
  padding-right: 15px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.userName {
  color: var(--white-text-color);
}

.userNameTitle {
  font-size: 12px;
  color: var(--transparent-light-white-4);
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificationItem {
  flex: 1;
}

.description {
  line-height: 18px;
  font-size: 12px;
  height: 18px;
  color: var(--text-primary-light-color);
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}