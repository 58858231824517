.fileInput {
  display: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  user-select: none;
  cursor: pointer;
  padding: 7px 15px;
  outline: none;
  border: 1px solid transparent;
  transition: background-color .1s, color .1s, border-color .1s;
  text-decoration: none;

  &.medium {
    padding: 9px 25px;
    .buttonTextContainer {
      font-size: 18px;
      font-weight: 500;
    }
  }

  &.onlyIcon {
    padding: 0;
    height: 32px;
    width: 32px;
  }

  &.fullWidth {
    width: 100%;
  }

  &.left {
    .buttonTextContainer {
      margin-left: 10px;
    }
  }

  &.right {
    flex-direction: row-reverse;

    .buttonTextContainer {
      margin-right: 10px;
    }
  }

  &.primary-gradient {
    color: var(--white-text-color);
    background: var(--primary-gradient);

    &:hover {
      background: var(--primary-gradient-hover);
    }
  }

  &.transparent-full {
    color: var(--white-text-color);
    background: transparent;

    &.withBorder {
      border-color: var(--transparent-light-black);
    }
  }

  &.transparent-light-white {
    color: var(--white-text-color);
    background: var(--transparent-light-white);

    &.withBorder {
      border-color: var(--transparent-light-black);
    }
  }

  &.transparent-light-white-2 {
    color: var(--white-text-color);
    background: var(--transparent-light-white-2);

    &.withBorder {
      border-color: var(--transparent-light-black);
    }

    div {
      color: var(--white-text-color)
    }
  }

  &.primary {
    background: var(--primary-color);
    color: var(--white-text-color);

    .loaderContainer {
      div {
        border-color: var(--white-text-color) transparent transparent transparent;
      }
    }
  }

  &.light {
    background: var(--white-background-color);
    color: var(--text-primary-color);

    &.withBorder {
      border-color: var(--white-background-color);
    }

    .loaderContainer {
      div {
        border-color: var(--text-primary-color) transparent transparent transparent;
      }
    }
  }

  &.secondary {
    background: var(--secondary-color);
    color: var(--text-primary-color);

    &.withBorder {
      border-color: var(--light-color);
    }

    &:hover {
      background: #D9E0E5;

      &.withBorder {
        border-color: #D9E0E5;
      }
    }

    .loaderContainer {
      div {
        border-color: var(--text-primary-color) transparent transparent transparent;
      }
    }
  }

  &.landing-theme {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(126.86deg, #FF4E4E 0%, #E93939 100%);
    color: white;
    border-radius: 100px;
    padding: 13px 30px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
  }

  &:active {
    opacity: 0.8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.iconContainer {
  display: flex;
}

.buttonTextContainer {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.badge {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--white-text-color);

  &.client {
    background: var(--white-text-color);
  }
}

.loaderContainer {
  display: inline-block;
  position: relative;
  width: 15px;

  &::after {
    content: '';
    margin-top: 100%;
    display: block;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: orange transparent transparent transparent;
    overflow: hidden;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.30s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

