.comment {
  & * {
    color: inherit!important;
    border: none!important;
    visibility: visible!important;
  }

  .mention {
    color: var(--primary-color)!important;
    display: inline-block;
  }

  & > div > div:first-child {
    overflow: inherit!important;
    text-overflow: inherit;
    white-space: inherit!important;
    display: inherit;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;
  }
}

.comment__input {
  display: none!important;
}