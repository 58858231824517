.noResult {
  color: #104665;
  opacity: 0.5;
  margin-top: 100px;

  .firstTitle {
    font-weight: 700;
    font-size: 44px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .secondTitle {
    font-size: 16px;
    display: flex;
    justify-content: center;
  }

  &.clientNoResult {
    opacity: 1;

    .firstTitle {
      color: white;
    }

    .secondTitle {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
