.linkForm {
  background: var(--body-color);
  padding: 12px 15px;
  border-radius: var(--border-radius);
  border: 1px solid rgba(16, 70, 101, 0.1);
  position: relative;
  height: 100%;
}

.linkTitle {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: block;
}

.removeLinkButton {
  color: var(--text-primary-light-color);
  position: absolute;
  font-size: 0;
  right: 15px;
  top: 16px;
  cursor: pointer;
}

.manualForm {
  margin: 0 -15px;
}

.addManualButtonMain {
  background: transparent !important;
}

.addManualButtonText {
  color: var(--primary-color);
}

.addManualButtonRow {
  display: none;
  justify-content: center;
  border-top: 1px solid var(--ligh-darkest-color);
  padding-top: 12px;
}

.addManualContainer {
  display: none;
  padding-bottom: 0;
  flex-direction: column;
}

.addManualShow {
  display: flex;
}

.innerCont {
  padding: 0 16px;
}

.metricsLineContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  margin: 0 -10px;
}

.metricsItemCont {
  display: flex;
  width: 82px;
  overflow: hidden;
  align-items: center;
  border-radius: var(--border-radius);
  padding: 3px 10px;
  background: var(--white-background-color);
  flex: 1;
  margin: 0 10px;

  input {
    border: none;
    outline: none;
    font-size: 14px;
  }
}

.metricsIcon {
  display: flex;
}

.metricsInputWrapper {
  overflow: hidden;
  padding-left: 5px;
}

.metricsPostfix {
  display: none;
}

.leaveMetricHint {
  font-size: 10px;
  font-weight: 500;
  color: var(--text-primary-light-color);
  padding: 4px 12px;
}

.inputLinkWrapper {
  display: flex;
  align-items: center;
  border: solid var(--ligh-darkest-color) 1px;
  border-radius: var(--border-radius);
  background: var(--white-background-color);
  overflow: hidden;

  label {
    width: 100%;
  }

  &.notValid {
    border-color: var(--danger-color);
  }
}

.indicatorWrapper {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin: 0px 8px;

  svg {
    display: block;
  }
}

.loadingStateStyle label {
  opacity: .4;
  filter: grayscale(50%);
}

.parsingMessageContainer {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.parsingLinkMessage {
  color: var(--text-primary-color);
  opacity: .5;
}

.parsingMessageSuccess {
  color: var(--success-color);
}

.parsingMessageError {
  color: var(--danger-color);
  opacity: .9;
}
