@import "variables";

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Mukta', sans-serif;
  color: var(--text-color);
  position: relative;
  min-height: 100vh;
  height: 100%;
}

pre {
  font-family: 'Mukta', sans-serif;
}

body {
  &.client {
    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background: #000;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 20px;
      background-color: #334248;
    }
  }
}

#root {
  min-height: 100vh;
  height: 100%;
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.notifications-component {
  position: fixed;
  bottom: 40px;
  left: 18px;
  z-index: 2;
}

.notification {
  margin-top: 16px;
}

.rdw-right-aligned-block *{
  text-align: right!important;
}

.rdw-center-aligned-block *{
  text-align: center!important;
}

.rdw-justify-aligned-block *{
  text-align: justify!important;
}
