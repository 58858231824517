.labelStyle {
  span {
    padding-left: 1px !important;
  }

  label {
    padding-right: 2px;
  }

  textarea {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 10px;
      background-color: rgba(175, 175, 175, 0.66);
    }
  }
}
