.headingFilters {
  padding: 20px 0;
  position: relative;
  z-index: 3;
  margin-bottom: 20px;

  &.client {
    background: rgba(0, 0, 0, 0.15);

    .title {
      color: var(--white-text-color);
    }
  }
}

.title {
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  flex: 0 0 270px;
  margin: 0 30px 0 0;

  @media all and (max-width: 740px) {
    flex: 0 0 50px;
    margin: 0;
  }
}

.filtersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 740px) {
    flex-direction: column;
    align-content: flex-start;
  }
}

.backIcon {
  transform: rotate(180deg);
}

.backButton {
  padding: 12px 15px;
}

.adminBackButton {
  background: white !important;
  margin-right: 12px;
  padding: 10px !important;
}

.filters {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  & > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &.collectionStyle {
    flex: 0 0 calc(100% - 300px);
    margin-left: auto;
  }


  @media all and (max-width: 740px) {
    width: 100%;
  }
}
