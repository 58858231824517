.emojiPicker {
  width: 100%;
  height: 100%;
  font-size: 13px;
  background: #041923;
  user-select: none;
  text-align: left;
  border-radius: 7px;
  border: solid 1px #425e69;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  .searchWrap {
    padding: 10px;
    padding-bottom: 0;
  }

  .fixedPanel {
    border-bottom: solid 1px #425e69;
  }

  .recentlyWrap {
    padding: 10px;
  }

  .emojiContainer {
    padding: 10px;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 20px;
      background-color: var(--transparent-light-white-4);
    }

    .emojiBody {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .emojiGroup {
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;

        &>div{
          padding-left: 5px;
        }

        &:first-child {
          padding: 5px 0 !important;
        }
      }
    }

    .topBar {
      display: flex;
      align-items: center;

      &:first-child {
        flex-grow: 2;
      }

      &:last-child {
        padding: 10px;
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  .emojiFooter {
    -webkit-box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #425e69;
    margin-bottom: 10px;

    div {
      fill: white;
      height: 100%;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        fill: #425e69;
        cursor: pointer;
      }
    }
  }

  .emojiGrid {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));

    div {
      height: 35px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.emojiSearch {
  display: flex;
  padding: 5px 8px;
  border-radius: 8px;
  width: 100%;
  background: #041923;
  border: solid 1px #425e69 !important;

  input {
    outline: none;
    background: none;
    width: 100%;
    border: 0;
    font-size: 16px;
    color: white;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.emojiRecentlyTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  &:last-child {
    font-size: 12px;
    font-weight: 300;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}
