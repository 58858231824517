.glossaryMainContainer {
  .glossaryTitle {
    color: var(--white-text-color);
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
  }
}

.contentWrap {
  display: flex;
  flex-direction: column;

  img{
    align-self: center;
    max-width: 100%;
    margin: 15px 0;
  }

  p, ol, ul {
    color: white;
    margin-block-start: .5em;
    margin-block-end: .5em;
  }

  a {
    color: #1CA5E0 !important;
    p, span {
      color: #1CA5E0 !important;
    }
  }
}