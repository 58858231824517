.trend {
  padding: 12px 48px 12px 14px;
  border-radius: var(--border-radius);
  background: var(--transparent-light-white);
  color: var(--white-text-color);
  line-height: 1;
  font-weight: 800;
  font-size: 18px;
  margin: 7px 15px;
  width: calc((100% / 3) - 30px);
  position: relative;
  cursor: pointer;

  &:hover {
    .goToTrendButton {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  @media all and (max-width: 740px) {
    width: 100%;
  }
}

.typeKeysIcons {
  margin-right: 5px;
}

.trendTitle {
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.trendTitleWrapper {
  display: flex;
  align-items: center;
  padding-right: 10px;
  line-height: 25px;
}

.goToTrendButton {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  background: var(--transparent-light-white);
  transition: background .1s;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}