.selectWrapper {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 150px;
  max-width: 100%;

  &.fullWidth {
    display: block;
  }

  &.transparent-light-white {
    .selectItem {
      color: var(--white-text-color);
      background: var(--transparent-light-white);
      border-color: transparent;
    }

    .option {
      color: var(--white-text-color);
    }

    .selectOptions {
      background: var(--dark);
      border-color: transparent;
    }

    .selectIcon {
      color: var(--primary-color);
    }
  }

  &.selectWrapperActive {
    z-index: 2;
  }
}

.selectItem {
  padding: 4px 30px 3px 16px;
  border: 1px solid var(--light-color);
  border-radius: var(--border-radius);
  color: var(--text-primary-color);
  font-size: 14px;
  cursor: pointer;
  position: relative;
  background: var(--white-background-color);

  &.active {
    .selectIcon {
      transform: rotateX(180deg);
    }
  }

  &.error {
    border-color: var(--danger-color);
  }
}

.errorMessage {
  color: var(--danger-color);
  font-size: 14px;
  padding: 0 16px;
}

.selectIcon {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  transition: transform .3s;
}

.selectText {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;

  &.whiteSpaceNormal {
    white-space: normal;
  }
}

.selectOptions {
  padding: 12px 10px;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-color);
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  transform: translateY(8px);
  background: var(--white-background-color);
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  display: none;

  &.open {
    display: block;
  }

  &.scrollStyle {
    max-height: 280px;
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--text-primary-light-color);
  }
}

.checkBox {
  flex: 0 0 12px;
  height: 12px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--text-primary-color);
  margin-left: auto;
  position: relative;

  &.checked {
    border-color: var(--primary-color);

    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background: var(--primary-color);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.optionName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;
}

.option {
  font-size: 14px;
  line-height: 22px;
  padding: 5px 8px;
  color: var(--text-primary-color);
  border-radius: var(--border-radius-small);
  transition: all .2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &.selected, &:hover {
    &:not(.multiple) {
      color: var(--primary-light-color);
    }

    background: var(--primary-light-gradient-color);
  }
}

.searchWrapper {
  margin-bottom: 4px;

  input {
    min-width: 0;
  }
}

.flagWrap {
  background: rgba(216, 216, 224, 0.44);
  margin-right: 5px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 11px;
  color: #efa200;
}
