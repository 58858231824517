.createCollectionContainer {
  display: flex;
  flex-direction: column;

  .shareCollectionContainer {
    padding-bottom: 193px;
    width: 504px;

    [aria-label="select options"] {
      height: 190px;

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-color: #DDE4E9;
      }
    }
  }

  &.adminStyleType {
    .inputBlock {
      input, textarea {
        border: 1px var(--body-color) solid !important;
        color: var(--text-primary-color) !important;

        &:focus {
          outline: 1px solid #b0b0b0 !important;
        }
      }

      textarea {
        &::-webkit-scrollbar-thumb {
          background-color: #c7c7c7 !important;
        }
      }

      div:first-child {
        color: var(--text-primary-color) !important;
      }
    }
  }

  .inputBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    div:first-child {
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    input, textarea {
      border-radius: var(--border-radius);
      background: rgba(255, 255, 255, 0.05);
      color: white;
      border: none;
      padding: 10px;

      &:focus {
        outline: 1px solid #1ca5e0;
      }
    }

    input {
      width: 100%;
    }

    textarea {
      width: 505px;
      height: 112px;
      resize: none;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: padding-box;
        border-radius: 20px;
        background-color: var(--transparent-light-white-4);
      }
    }
  }
}
