.inputWrapper {
  border-radius: var(--border-radius);
  border: 1px solid var(--light-color);
  padding: 3px 10px;
  display: inline-block;
  font-size: 14px;
  background: var(--white-background-color);
  position: relative;

  &.disabled {
    .input {
      cursor: not-allowed;
    }
  }

  &.textarea {
    flex: 1;

    .input {
      resize: none;
      height: 100%;
    }
  }

  &.withPrefix {
    .input {
      margin-left: 10px;
    }
  }

  &.transparent-light-white {
    background: var(--transparent-light-white-2);
    border-color: transparent;

    .inputIcon{
      color: var(--transparent-light-white-2);
    }

    .input {
      color: var(--transparent-light-white-4);

      &::placeholder {
        color: var(--transparent-light-white-4);
      }
    }
  }

  &.fullWidth {
    display: block;
  }

  &.withIcon {
    display: flex;
    align-items: center;
  }
}

.input {
  outline: none;
  border: none;
  width: 100%;
  color: var(--text-primary-color);
  line-height: 22px;
  background: transparent;

  &::placeholder {
    color: var(--text-primary-light-color);
    font-size: 14px;
  }
}

.inputIcon {
  color: var(--text-primary-light-color);
  display: flex;
  margin-right: 5px;
}

.prefix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-primary-color);
}

.error {
  border-color: var(--danger-color);
}

.errorText {
  font-size: 14px;
  color: var(--danger-color);
  padding: 0 12px;
}

.notBordered {
  border: none;
  background: transparent;
  border-radius: 0;
}