.commentsContainer {
  padding-bottom: 300px;
}

.commentsTitle {
  color: var(--white-text-color);
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  display: inline-block;
}

.commentsTitleWrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.commentsHeader {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--transparent-light-white-2);
  margin-bottom: 25px;
}

.badgeCount {
  background: var(--primary-gradient);
  min-width: 28px;
  min-height: 28px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  border-radius: 50%;
  color: var(--white-text-color);
  margin-left: 15px;
  font-weight: 700;
  font-size: 14px;
}

.filterItem + .filterItem {
  margin-left: 15px;
}
