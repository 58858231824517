.spinnerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  border: solid var(--body-color);
  border-top-color: var(--text-primary-color);
  animation: spinner 1.8s linear infinite;
}

.spinner.size-extra-sm:before {
  border-width: 3px;
  border-color: #1CA5E0;
  border-top-color: #FFFFFF1A;
  border-right-color: #FFFFFF1A;
}

.spinner.size-sm:before {
  border-width: 2px;
}

.spinner.size-md:before {
  border-width: 4px;
}

.spinner.size-lg:before {
  border-width: 8px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}