.checkItemLine {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 15px !important;

  &.checkLineClient {
    &:hover {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.05),  rgba(255, 255, 255, 0.01));

      .titleStyle {
        color: var(--white-text-color);
      }
    }

    .titleStyle {
      color: rgba(255, 255, 255, 0.47);
    }

    &.checkedItemLine {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.05),  rgba(255, 255, 255, 0.01));

      .titleStyle {
        color: var(--white-text-color);
      }
    }
  }
}

.container {
  color: var(--primary-color);
  padding-right: 8px;
  display: flex;
  align-items: center;
}

.unselectedContainer {
  color: var(--text-primary-color);
}

.unselectedContainerClient {
  color: var(--transparent-light-white-5);
}

.checkLineName {
  font-size: 14px;
  display: flex;
}

.logoLinkContainer {
  width: 26px;
  height: 26px;
  margin-right: 5px;

  img {
    filter: invert(.65);
    opacity: .7;
    width: 100%;
    height: 100%;
  }

  &.logoLinkClient {
    img {
      filter: invert(0);
    }
  }
}

.flagWrap {
  background: rgba(216, 216, 224, 0.44);
  margin-right: 5px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 11px;
  color: #efa200;

  &.flagWrapClient {
    background: rgba(238, 238, 238, 0.09);
    color: var(--white-text-color);
  }
}