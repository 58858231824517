.reportContainer {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-large);
  background: var(--transparent-light-white);
  height: 100%;
  overflow: hidden;
  position: relative;
}

.date {
  font-size: 12px;
  color: var(--white-text-color);

  position: absolute;
  top: 8px;
  left: 12px;
  z-index: 2;
}

.imageWraper {
  width: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    margin-top: 60%;
  }
}

.contentContainer {
  padding: 12px 12px 8px;
}

.viewButton {
  border-radius: 0;
  background: var(--primary-gradient);
  width: 100%;
}

.viewButtonText {
  font-size: 12px;
  padding: 4px;
  font-weight: 700;
}

.description {
  font-size: 12px;
  color: var(--white-text-color);
  opacity: .4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
}

.trends {
  margin-top: 4px;
  font-weight: 700;
  font-size: 12px;
  opacity: .7;
  color: var(--white-text-color);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white-text-color);
  font-weight: 700;
  font-size: 16px;
}

.trendsContainer {
  background: var(--transparent-light-white);
  padding: 8px;
  border-radius: 2px;
  height: 76px;
}

.trendItem {
  color: var(--white-text-color);
  font-size: 12px;
  font-weight: 400;
  opacity: .7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

}

.badgesLine {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-top: 15px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 39.47%);

  img, svg {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
  }

  svg {
    padding: 10px;
  }
}
