.row {
  display: flex;
}

.title {
  flex: 1;
  text-align: center;
}

.asideSection {
  flex: 0 0 200px;
  margin: 19px 0;

  .declineButton {
    margin-top: 2px;
    padding: 6px 24px;

    .declineButtonText {
      font-size: 16px;
      line-height: 25.6px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.footerRightContent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
}