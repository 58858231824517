@import "scss/mixins/entity-list";

.user {
  @include entity-list-item;

  &:not(.disabled):hover {
    background: #1DA1F225;
  }
}

.userNameWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.editButton {
  @include entity-edit-action;
}

.deleteButton {
  @include entity-delete-action;
}

.checkBox {
  @include entity-checkbox-item;
}

.userName {
  @include entity-list-item-name;
}

.actions {
  @include entity-list-item-actions;
}

.tag {
  @include entity-list-item-tag;
}