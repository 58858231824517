@mixin gradient-layout {
  background: var(--client-body-color);

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url("/img/head-gradient.png") no-repeat top left, url("/img/gradient-bg.png") repeat-y top -100px center;
    background-size: auto, 100%;
  }
}