.contentContainer {
  min-width: 270px;
  background: var(--white-background-color);
  box-shadow: 0 0 12px var(--body-color);
  border-radius: var(--border-radius);
  user-select: none;
  position: relative;
  padding-bottom: 12px;

  &.mention {
    background: #303030;
    box-shadow: 0 0 12px var(--dark);
  }
}

.titleContainer {
  display: flex;
  position: relative;
  color: var(--text-primary-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 12px 0 12px;

  .titleText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px 0 9px;
  }
}

.description {
  line-height: 24px;
  height: 24px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 5px;

  &.mention {
    color: var(--transparent-light-white-4)!important;
  }
}


.closeButtonContainer {
  color: var(--text-primary-light-color);
  cursor: pointer;
  user-select: none;
  align-self: flex-start;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 5;

  &:active {
    opacity: 0.7;
  }
}

.linkString {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: .5;
  padding-left: 39px;
  padding-right: 10px;
  color: var(--text-primary-color);
  font-weight: 400;
  font-size: 12px;
}

.footer {
  border-top: 1px solid var(--main-background-color);
  padding: 12px 12px 0 12px;
  display: flex;
  justify-content: space-between;

  .footerLeftPart {
    color: var(--text-primary-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: flex;
  }

  .footerRightPart {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
}

.titleIconContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: var(--danger-color);
}

.success {
  color: var(--success-color);
}
