.formWrapper {
  position: relative;
  margin-bottom: 35px;
  padding: 16px 15px;
  background: var(--transparent-light-white-0-5);
  border: 1px solid var(--transparent-light-white-2);
  border-radius: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.editStateWrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.textFieldWrapper {
  flex: 1;
  padding-right: 15px;
  display: flex;
  align-items: center;

  &.textStateWrapper {
    flex: none;
    width: 100%;
  }

  textarea {
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent !important;
    scrollbar-width: thin !important;
  }
}

.textField {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  color: var(--white-text-color);
  font-size: 16px;
  padding: 0;
  resize: none;
  max-height: 76px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
}

.sendButton {
  width: 54px;
  height: 54px;
}

.transparentButton {
  background: transparent;
}

.repliedCommentHeader {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--primary-color);
  justify-content: space-between;
}

.removeReplyButton {
  cursor: pointer;
  color: var(--white-text-color);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--transparent-light-white-2);
}

.replyHeadTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 12px;
  }
}

.repliedContent {
  font-size: 16px;
  color: var(--white-text-color);
  padding-left: 7px;
  border-left: 1px solid var(--white-text-color);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.repliedComment {
  padding-left: 15px;
  margin-bottom: 10px;
}

.actionsWrapper {
  display: flex;
  align-items: flex-end;
}

.submitWrapper {
  display: flex;
  margin-left: 25px;
}

.attachIconWrapper {
  margin: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  flex-shrink: 0;

  &:active {
    opacity: .8;
  }

  &.attachIconDisabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.attachIconDisabled:hover {
    opacity: .5;
  }
}

.actionButton {
  padding: 10px 25px;
  margin-right: 12px;
}

.input {
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  visibility: hidden;
  position: absolute;
}

.attachedImgWrap {
  margin-right: 9px;
}

.uploadedImgsContainer {
  display: flex;
  align-items: flex-end;
}

.imgItemBlock {
  display: flex;
  width: 100%;
  padding-top: 16px;
  min-height: 65px;
}

.attachmentsContainer {
  display: flex;
  margin-top: 10px;
}

.emojiWrapper {
  margin: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  flex-shrink: 0;
}
