.tagsWrapper {
  margin: 30px 0;
}

.tagsTitle {
  color: var(--white-text-color);
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  margin: 10px 0 0 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tagWrapper {
  font-size: 14px;
  line-height: 24px;
  padding: 5px 16px;
  margin: 0 8px 16px 0;
  display: flex;
  box-sizing: border-box;

  border-radius: 2px;
  background: rgba(63, 63, 63, 0.6);;
  color: #FFFFFF;

  .hashTag {
    marginRight: '2px';
  }
}