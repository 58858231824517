.privacyMainContainer {
  .paragraphLink, .mailLine {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
    padding-left: 6px;
  }

  .paragraphHeader {
    color: var(--white-text-color);
    font-weight: 800;
    font-size: 24px;
    opacity: 1;
  }

  .privacyTitle {
    color: var(--white-text-color);
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
  }

  p, li {
    color: var(--white-text-color);
    opacity: .7;
    font-size: 16px;
    line-height: 26px;
  }
}