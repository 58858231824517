.reportHeader {
  margin-bottom: 50px;
}

.backWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;

  @media all and (max-width: 970px) {
    flex-direction: column;

    & > a {
      align-self: flex-start;
    }
    & > div {
      align-self: flex-end;
      margin-top: 10px;
    }
  }
}

.dateData {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--transparent-light-white-7);
  margin-left: 30px;

  .date {
    font-weight: bold;
    color: var(--white-text-color);
    margin-left: 5px;
  }
}

.backIcon {
  transform: rotate(180deg);
}

.backButton {
  padding: 12px 15px;
}

.titleReport {
  font-weight: 800;
  font-size: 72px;
  line-height: 62px;
  margin: 0;
  text-transform: uppercase;
  padding: 0 15px;
  flex: 1;
  overflow-wrap: break-word;
}

.titleReportFull {
  max-width: 100%;
}

.descriptionReport {
  color: var(--white-text-color);
  padding: 0 15px;
  opacity: .7;
  margin: 0;
  flex: 1;
  font-size: 22px;
  line-height: 38px;
  white-space: pre-line;

  a {
    color: var(--primary-light-color)
  }
}

.reportHeadingContent {
  display: flex;
  flex-direction: column;
  color: var(--white-text-color);
  margin: 45px -15px;
}

.tags {
  padding: 20px 15px;
}

.tag {
  color: var(--white-text-color);
  font-size: 14px;
  background: rgba(63, 63, 63, 0.6);
  vertical-align: middle;
  padding: 6px 12px;
  border-radius: 50px;
  margin-top: 5px;
  margin-right: 10px;
  display: inline-flex;
}

.dates {
  display: flex;
}

.flagWrap {
  background: rgba(255, 255, 255, 0.11);
  margin-right: 5px;
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-weight: 700;
  color: rgba(255, 120, 21, 0.64);
}

.addCollectionButton {
  margin-bottom: -22px;
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
}

.peopleTags {
  padding: 0px 15px;

  .peopleHeader {
    font-weight: 500;
    color: white;
    font-size: 16px;
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;

    &>div {
      margin-top: 8px;
    }
  }
}
