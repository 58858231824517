.checkItemsContainer {
  padding-top: 4px;
  min-height: 200px;
  max-height: 380px;
  overflow-y: auto;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 4px;
  margin-bottom: -10px;
  scrollbar-color: #27373b transparent !important;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #a0acb7;
  }

  &.clientCheckItemsContainer {
    &::-webkit-scrollbar {
      background: rgba(0, 0, 0, 0.26);
      border-radius: 100px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      border-radius: 4px;
      background-color: #27373b;
    }
  }
}

.checkboxContainer {
  padding: 4px 0;
  margin: 1px 0;
}

.checkboxTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
}

.filterSearchWrap {
  padding: 5px 8px;
  width: 100%;
  border: solid 1px lightgrey;
  border-radius: 6px;
  display: flex;
  align-items: center;

  .filterSearchIcon {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    svg {
      width: 100%;
      height: 100%;
      display: block;
      color: lightgrey;
    }
  }

  input {
    color: #414141;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    width: 85%;
  }

  &.clientSearchView {
    margin-top: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.07);
    padding: 8px 10px;

    input {
      color: white;
    }
  }
}
