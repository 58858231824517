@import "scss/mixins/general";

.reportPage {
  padding: 24px 0;

  &.isPreview {
    padding: 0 0 24px 0;
    margin-bottom: -30px;

    @include gradient-layout;
  }
}

.previewHeader {
  padding: 15px 0;
  background: rgba(255, 255, 255, .8);
  position: sticky;
  z-index: 3;
  top: 79px;
  margin-bottom: 40px;
}

.previewContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary-color);
  font-size: 24px;
  font-weight: bold;
}

.trendCategory {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  color: var(--white-text-color);
  margin: 0 0 15px 0;
}

.categoryTrends {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.actionButton {
  padding: 10px 25px;
  margin-right: 12px;
}

.creator {
  background: var(--body-color);
  border-radius: var(--border-radius);
  margin-right: 12px;
  padding: 6px 14px;
  display: flex;
  align-items: center;
}

.currentEditor {
  background: var(--body-color);
  border-radius: 17px;
  margin-right: 12px;
  padding: 6px 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.editorIcon {
  font-size: 0;
  margin-right: 8px;
  color: var(--success-color);
}

.creatorText {
  color: var(--text-primary-light-color);
  font-size: 12px;
  display: flex;
  align-items: center;
}

.creatorEmail {
  font-size: 12px;
  margin-left: 5px;
}

.actionButtons {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: -12px;
}