.wrapper {
  background: var(--white-background-color);
  border: 1px solid rgba(16, 70, 101, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-primary-color);
  position: relative;
  height: 373px;
  overflow: hidden;

  &:hover {
    .editButtons {
      opacity: 1;
    }
  }

  .imgWrapper {
    width: 134px;
    height: 134px;
    margin: 15px 0px;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    background: #f7f9fa;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 50%;
      height: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    padding-bottom: 10px;
    height: 80px;
    overflow: hidden;
    align-content: flex-start;

    & > div {
      margin-right: 4px;
      margin-top: 10px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tagsEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    & > div:first-child{
      font-weight: 700;
      font-size: 22px;
    }

    & > div:last-child{
      font-weight: 500;
      font-size: 14px;
    }
  }

  .statItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px 30px;
    width: 100%;
    border-radius: var(--border-radius);
    background: rgba(238, 241, 243, 0.45);

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .tagsEmpty {
    color: rgba(16, 70, 101, 0.5);
  }

  &.clientWrapper {
    background: var(--transparent-light-white);
    color: white;

    .deletePeopleButton {
      border: none;
      background: rgba(255, 255, 255, 0.2);
      color: white;

      svg {
        height: 20px !important;
      }
    }

    .statItem {
      background: rgba(255, 255, 255, 0.04);
    }

    .imgWrapper {
      background: rgba(255, 255, 255, 0.06);
      border: rgba(255, 255, 255, 0.19);
    }

    .tagsContainer {
      & > div {
        background: rgba(255, 255, 255, 0.1);
        color: white;
      }
    }

    .tagsEmpty {
      color: rgba(255, 255, 255, 0.3);

      & > div:first-child {
        color: rgba(255, 255, 255, 0.1);
      }
    }

    .addCollection {
      top: 20px;

      .addCollectionButton {
        border: none;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.2) !important;
        color: white;

        svg {
          height: 20px !important;
        }
      }
    }
  }
}

.title {
  font-size: 24px;
  margin: 0;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statTitle {
  font-size: 14px;
  line-height: 23px;
  opacity: .5;
}

.statsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.statValue {
  line-height: 1;
  font-size: 20px;
  font-weight: 700;
}

.editButtons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16px;
  top: 16px;

  .deleteButton {
    border-radius: 6px 6px 0 0;
    background: #EEF1F3;
  }

  .deletePeopleButton {
    background: #EEF1F3;
  }

  .editButton {
    border-radius: 0;
    background: #EEF1F3;
    border-top: 1px solid #DDE4E9;
  }
}

.addCollection {
  position: absolute;
  right: 16px;
  top: 80px;
}

.addCollectionButton {
  border-top: 1px solid #DDE4E9;
  border-radius: 0 0 6px 6px;
  background: #EEF1F3 !important;

  svg {
    width: 16px!important;
    height: 16px!important;
  }
}
