.formRow {
    display: flex;
    align-items: center;
}

.typeKeyNameField {
    flex: 1;
}

.typeKeyEmojiField {
    padding-top: 5px;
    flex: 0 0 100px;
    margin-left: 10px;
}