.loaderContainer {
  display: flex;
  justify-content: center;

}

.loaderWindow {
  width: 430px;
  height: 430px;
  background: var(--white-background-color);
  padding: 40px;
  margin-top: 40px;
}

.title {
  color: var(--text-color);
  font-size: 32px;
  text-align: center;
  padding-bottom: 20px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  opacity: .5;
  text-align: center;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.spinnerContainer {
  width: 195px;
  height: 195px;
  position: relative;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  border: 20px solid var(--body-color);
  border-top-color: var(--text-primary-color);
  animation: spinner 1.8s linear infinite;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}