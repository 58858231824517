@mixin entity-list {
  background: var(--white-background-color);
  padding: 15px 10px;
  border-radius: var(--border-radius);
}

@mixin entity-list-item-tag {
  color: var(--text-primary-light-color);
  background: var(--body-color);
  border-radius: 20px;
  border: 1px solid var(--light-color);
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  min-width: 70px;
  padding: 0 8px;
}

@mixin entity-edit-action {
  &:hover {
    color: var(--primary-color);
  }
}

@mixin entity-delete-action {
  &:hover {
    color: var(--danger-color);
  }
}

@mixin entity-list-item-actions {
  display: flex;
  align-items: stretch;

  & > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

@mixin entity-list-item-wrapper {
  flex: 1;
  max-width: 100%;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 6px;
    margin-bottom: 6px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background: var(--light-color);;
    }
  }
}

@mixin entity-checkbox-item {
  flex: 0 0 18px;
  height: 18px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--text-primary-color);
  position: relative;

  &.checked {
    border-color: var(--primary-color);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: var(--primary-color);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@mixin entity-list-item {
  background: var(--white-background-color);
  padding: 6px 12px 6px 15px;
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  position: relative;
}

@mixin entity-list-item-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--text-primary-color);
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: calc(100% - 120px);;
}