.link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;

  &.primary {
    color: var(--text-primary-color);
  }

  &.light {
    color: var(--white-text-color);
  }

  &:hover {
    text-decoration: none;
  }
}