.collectionItemContainer {
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 28px 18px 18px 18px;
  height: 100%;

  &.notAdminContainer {
    padding-bottom: 30px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #104665;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    padding-left: 10px;
    padding-right: 30px;
    margin-bottom: 3px;
  }

  .peopleCount {
    color: rgba(16, 70, 101, 0.4);
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    padding-bottom: 7px;

    span {
      margin-right: 4px;
    }
  }

  .peopleCountStub {
    height: 31px;
  }

  .avatarsContainer {
    cursor: pointer;
    margin: 40px 0 0 0;
  }

  .noPeopleWrap {
    width: 100%;
    height: 60px;
    border: 1px solid #EEF1F3;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 5px 0;
    margin: 40px 0 0 0;

    svg {
      height: 100%;
    }
  }

  &.clientStyle {
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      .editButtons {
        opacity: 1;
        visibility: visible;
      }
    }

    .title {
      color: white;
    }

    .peopleCount {
      color: rgba(255, 255, 255, 0.4);
    }

    .noPeopleWrap {
      opacity: .1;
    }

    .editButtons {
      opacity: 0;
      visibility: hidden;
      transition: all .1s;

      button {
        background: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.6);

        &:after {
          background-color: #2C2F31;
        }
      }
    }
  }
}

.editButtons {
  display: flex;
  position: absolute;
  flex-direction: column;
  right: 8px;
  top: 8px;

  button:not(:last-child) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 80%;
      height: 1px;
      background: #DDE4E9;
      bottom: 0;
    }
  }

  .deleteButton {
    border-radius: 6px 6px 0 0;
    background: #EEF1F3;

    &:hover {
      color: var(--danger-color) !important;
    }
  }

  .editButton {
    border-radius: 0;
    background: #EEF1F3;

    &:hover {
      color: var(--primary-light-color) !important;
    }
  }

  .shareButton {
    border-radius: 0 0 6px 6px;
    background: #EEF1F3;

    &:hover {
      color: var(--primary-light-color) !important;
    }
  }
}

.adminItem {
  display: flex;

  &:first-child {
    padding: 25px 0px 7px 0px;
  }
}
