.peopleSelectContainer {
  border: solid 1px orange;
}

.selectWrapper {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 150px;
  max-width: 100%;

  &.fullWidth {
    display: block;
  }

  &.selectWrapperActive {
    z-index: 2;
  }
}

.selectItem {
  padding: 4px 30px 3px 16px;
  border: 1px solid var(--light-color);
  border-radius: var(--border-radius);
  color: var(--text-primary-color);
  font-size: 14px;
  cursor: pointer;
  position: relative;
  background: var(--white-background-color);

  &.active {
    .selectIcon {
      transform: rotateX(180deg);
    }
  }

  &.error {
    border-color: var(--danger-color);
  }
}

.errorMessage {
  color: var(--danger-color);
  font-size: 14px;
  padding: 0 16px;
}

.selectIcon {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  transition: transform .3s;
}

.selectText {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;

  &.whiteSpaceNormal {
    white-space: normal;
  }
}

.selectOptions {
  border-radius: var(--border-radius);
  border: 1px solid var(--light-color);
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  transform: translateY(8px);
  background: var(--white-background-color);
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  display: none;

  &.open {
    display: block;
  }
}

.optionName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;
}

.searchWrapper {
  margin-bottom: 4px;

  input {
    min-width: 0;
  }
}

.flagWrap {
  background: rgba(216, 216, 224, 0.44);
  margin-right: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 11px;
  color: #efa200;
}

.infiniteScrollBlock {
  min-height: 100px;
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 12px;
  padding-bottom: 6px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--text-primary-light-color);
  }
}

.footerBlock {
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  border-top: rgba(221, 228, 233, 0.5) solid 1px;
}

.searchBlock {
  padding: 12px;
}

.addNewButtonStyle {
  padding-left: 22px;
  padding-right: 22px;
}

.peopleItemContainer {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  width: 518px;
  margin-bottom: 2px;

  &:hover {
    cursor: pointer;
    background: rgba(29, 161, 242, 0.1) !important;
  }

  &.peopleChecked {
    background: rgba(29, 161, 242, 0.05);
  }
}

.badgeContainer {
  display: flex;
  border: solid rgba(136, 163, 178, 0.1) 1px;
  border-radius: 100px;
  background: #EEF1F3;
  padding: 0px 10px;
  color: #88A3B2;
  font-weight: 700;
  font-size: 11px;

  &.badgeHidden {
    visibility: hidden;
  }
}

.peopleItemTitle {
  color: #104665;
  font-weight: 500;
  font-size: 12px;
  width: 82%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badgeCount {
  padding-right: 4px;
}
