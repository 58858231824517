.thumb {
  background: var(--white-background-color);
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 40px;
  width: 43px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  outline: none;
}

.line {
  height: 8px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
}