@import "scss/mixins/entity-list";

.usersList {
  @include entity-list;

  .userItem {
    @include entity-list-item-wrapper;

    &.dark:not(:last-child):after {
      background: var(--transparent-light-white-0-5);
    }

  }

  &.dark {
    background: var(--transparent-light-white);
  }
}

.backButton {
  border-radius: var(--border-radius-small);
  background: var(--white-background-color);
  display: inline-block;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.backIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: rotate(-180deg);
}

.company {
  display: flex;
  align-items: center;
}

.companyName {
  margin: 0 0 0 12px;
  line-height: 1;
  font-size: 24px;
}

.companyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}