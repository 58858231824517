.fieldLabel {
  display: block;
  padding-left: 10px;
  font-weight: 500;
  font-size: 16px;
  color: var(--text-primary-color);
  line-height: 1;
  margin-bottom: 5px;
}

.fieldRow {
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}