.shareCollectionContainer {
  width: 370px;
  padding-bottom: 183px;

  [aria-label="select options"] {
    height: 190px;

    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-color: #DDE4E9;
    }
  }
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.checked {
  .iconContainer {
    color: var(--primary-color);
  }
}

.users {
  color: var(--text-primary-light-color);
  padding: 3px 6px;
  text-align: center;
  margin-right: 10px;
  flex-shrink: 0;
  border: 1px solid var(--light-color);
  background: var(--body-color);
  border-radius: 10px;
  font-size: 12px;
  line-height: 1;
}

.iconContainer {
  flex-shrink: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ligh-darkest-color);
}

.nameContainer {
  width: 100%;
  padding-left: 10px;
  color: var(--text-primary-color);
}