:global {
  .rdw-editor-toolbar[aria-hidden=true] {
    height: 0;
    margin: 0;
    padding: 0;
  }

  .public-DraftEditorPlaceholder-inner {
    color: rgba(16, 70, 101, 0.51);
  }

  .public-DraftEditorPlaceholder-root {
    margin-top: 5px;
    margin-left: 12px;
  }

  .public-DraftStyleDefault-block {
    margin: 5px 0 !important;
  }

  .DraftEditor-editorContainer {
    border: none !important;
  }

  .DraftEditor-editorContainer [data-contents]{
    padding: 5px 10px;
    height: 185px;
    overflow-y: auto;
    resize: vertical;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border: 1px solid var(--light-color);
    border-top: none;
  }

  .public-DraftEditorPlaceholder-root {
    padding-top: 5px;
  }
}

.big {
  :global {
    .DraftEditor-editorContainer [data-contents]{
      height: 455px;
      padding: 0 35px;
    }
  }
}

.mainContainer {
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border: 1px solid transparent;
}

.error {
  border-color: var(--danger-color);
}

.toolbarWrap {
  background: transparent !important;
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid var(--light-color) !important;
  border-bottom: none;
  margin-bottom: 0 !important;
}

.editorWrap {
  min-height: 100px;
  padding: 0;
  overflow-y: auto;

  &.dark {
    background: #214760;
  }
}

.errorText {
  font-size: 14px;
  color: var(--danger-color);
  padding: 0 12px;
}
