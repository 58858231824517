.notificationButton {
  width: 42px!important;
  height: 42px!important;

  &.unread {
    background: #1CA5E0;
    color: #FFF;

    &:hover {
      background: #1CA5E0;
    }
  }

  &.clientUnread {
    background: var(--primary-gradient);
    color: #FFF;

    &:hover {
      background: var(--primary-gradient);
    }
  }
}

.wrapper {
  position: relative;
}