.userAvatarWrapper {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  font-size: 0;
  border: 2px solid var(--white-text-color);
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;

  &.replied {
    width: 36px;
    height: 36px;
  }
}

.commentItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.commentContent {
  padding: 0 20px;
  flex: 1;
  max-width: calc(100% - 76px);
}

.commentText {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--white-text-color);
  margin: 15px 0 5px 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.commentDate {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #56656B;

  & div {
    display: inline-block;
  }
}

.userName {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

.commentActions {
  font-size: 16px;
  color: #858F96;
  font-weight: 600;
  display: flex;
  align-items: center;

  & > div {
    margin-right: 15px;
  }
}
.reply {
  cursor: pointer;
}

.likeAction {
  cursor: pointer;
  font-size: 0;
  align-self: center;
}

.repliedComment {
  margin-top: 15px;
  padding: 12px;
  border-radius: 8px;
  background: var(--transparent-light-white);
  display: flex;
  align-items: flex-start;
  max-width: 600px;
}

.repliedUserName {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-color);
}

.repliedText {
  color: var(--white-text-color);
  line-height: 26px;
  height: 26px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.repliedContent {
  padding: 0 10px;
  flex: 1;
  max-width: calc(100% - 40px);
}

.eventSection {
  position: absolute;
  right: 8px;
}

.eventWrapper {
  position: absolute;
  right: 12px;
  background: var(--events-background-color);
  color: var(--white-text-color);
  padding: 6px 15px;
  border-radius: 6px;
  width: 144px;
}

.eventButton {
  height: 28px;
  width: 24px;
  border-radius: 0;

  .spaceBetween {
    flex: 1;
    justify-content: space-between;
  }

  &.deleteButton {
    color: var(--danger-color);
  }
}

.edited {
  font-size: 14px;
  color: #858F96;
  padding-left: 10px;
}

.attachmentsContainer {
  display: flex;
  margin: 10px 0px;
}

.attachedImgWrap {
  margin-right: 9px;
}

.attachedImgContainer {
  margin-top: 10px;
  display: flex;
}