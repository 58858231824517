.container {
  background: #EEF1F3;
  border-radius: 4px;
  overflow: hidden;

  .eventButton {
    height: 24px;
    width: 24px;
    border-radius: 0;

    .eventIcon {
      height: 12px;
      width: 12px;
    }
  }

  .deleteButton:hover {
    color: var(--danger-color);
  }

  .editButton:hover {
    color: var(--primary-light-color);
  }
}