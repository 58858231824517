.overlay {
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.84);
}

.popupLayout {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 77px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  display: flex;
  z-index: 999;
  background: white;
  max-width: 50%;
  img {
    width: 100%;
  }
}
