.characterChainContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 60px;
  align-items: center;
  padding-left: 2px;

  &.clientStyle {
    .avatarWrap {
      margin-bottom: 0px;
      background: #293134;
      outline: 1px solid rgba(89, 104, 110, 0.2);
    }

    .avatarLastWrap {
      div {
        color: white;
      }
    }
  }

  &.capa3 {
    height: 60px;
    width: 100px;
    padding-left: 28px;

    .avatarWrap {
      border: #cfdae0 solid 1px;
      width: 48px;
      height: 48px;
      z-index: 0;
      margin-left: -24px;
      margin-bottom: 0px;

      img {
        position: relative;
      }
    }
  }

  &.capa4 {
    height: 128px;
    overflow: initial;
    padding-left: 82px;
    flex-wrap: nowrap;
    align-items: flex-start;

    .avatarWrap {
      width: 126px;
      height: 126px;
      margin-left: -80px;
    }
  }
}

.avatarWrap {
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: 1000px;
  margin-left: -26px;
  margin-bottom: 8px;
  background: #f7f9fa;
  outline: 1px solid rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: 50%;
    height: 50%;
  }

  &:first-child {
    margin-left: 0;
  }
}

.avatarLastWrap {
  background: #EEF1F3;
  justify-content: center;
  align-items: center;
  display: flex;

  div {
    font-size: 14px;
    font-weight: 400;
    color: #104665;
  }
}
