.trendWrapper {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  transform: scale(0.95) translateY(25px);
  transition: transform .5s;

  &:not(:last-child) {
    margin-bottom: 45px;
  }

  &.initScale {
    transform: none;
  }

  justify-content: space-between;

  @media all and (max-width: 740px) {
    transform: none;
    transition: none;
    flex-direction: column;

    &>div:first-child {
      height: 300px;
    }
  }
}

.trendContent {
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: var(--white-text-color);
}

.cartItem {
  height: 440px;
  flex: 0 0 calc(50% - 1px);
  padding: 25px 68px;
  position: relative;

  @media all and (max-width: 740px) {
    flex: none;
    padding: 10px;
  }
}

.kymIndexWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tagItem {
    color: var(--white-text-color);
    font-size: 14px;
    margin-right: 5px;
  }
}

.kymIndex {
  background: var(--transparent-overlay-black);
  color: var(--white-text-color);
  font-size: 14px;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: var(--border-radius-small);
  margin: 2px;
  cursor: pointer;
}

.kymIcon {
  font-size: 0;
  margin-right: 8px;
}

.kymIndexLabel {
  color: var(--white-text-color);
  opacity: .7;
  margin-left: 8px;
}

.trendItem {
  background: var(--transparent-light-white);
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullTrendWidth {
    flex: 100%;
  }
}

.trendTitle {
  font-size: 36px;
  font-weight: 800;
  margin: 0 0 15px 0;
  line-height: 36px;
  color: var(--white-text-color);
}

.trendDescription {
  color: var(--white-text-color);
  opacity: .7;
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  word-break: break-word;
  max-height: 160px;
  overflow: auto;
  white-space: pre-line;
  margin: 0 0 18px 0;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--transparent-light-white-4);
  }
}

.postItem {
  max-width: calc(50% - 1px);
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 90.76%);
  position: relative;

  &:hover {
    .postLogo{
      img {
        opacity: 1;
      }
    }
  }

  .postLogo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;

    img {
      width: 100%;
      height: 100%;
      opacity: .75;
    }
  }

  @media all and (max-width: 740px) {
    max-width: 100%;

  }
}

.tags {
  overflow-y: auto;
  max-height: 70px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: var(--transparent-light-white-4);
  }
}

.postTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--white-text-color);
  margin: 0 0 12px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  opacity: .8;
}

.postTitle:hover {
  opacity: 1;
}

.postImg, .postImg svg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
}

.postImg svg {
  padding: 30px;
}

.postContent {
  width: 100%;
}

.tag {
  color: var(--white-text-color);
  font-size: 14px;
  background: rgba(0, 0, 0, .6);
  display: inline-block;
  vertical-align: middle;
  padding: 3px 12px;
  border-radius: 50px;
  margin-top: 5px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.statistics {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.statCounter {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 28px;
  }
}


.statIcon {
  color: var(--primary-color)
}

.count {
  color: var(--white-text-color);
  opacity: 0.7;
  margin-left: 5px;
  font-size: 15px;
  line-height: 1;
}

.fullWidth {
  width: 100%;
  max-width: 100%;
  flex-basis: initial;
}