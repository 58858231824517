@import "scss/mixins/entity-list";

.user {
  @include entity-list-item;
  cursor: pointer;

  &.light:hover {
    background: #1DA1F225;
  }

  &.dark:hover {
    background: var(--transparent-light-white-2);
  }

  &.dark {
    background: transparent;
    .userName {
      color: var(--white-text-color);
    }

    .tag, .editButton, .deleteButton {
      background: var(--transparent-light-white-2);
      border-color: var(--transparent-light-black);
    }

  }
}

.userNameWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.editButton {
  @include entity-edit-action;
}

.deleteButton {
  @include entity-delete-action;
}

.checkBox {
  @include entity-checkbox-item;
}

.userName {
  @include entity-list-item-name;
}

.actions {
  @include entity-list-item-actions;
}

.tag {
  @include entity-list-item-tag;
}