.trendContainer {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-large);
  background: var(--transparent-light-white);
  height: 396px;
  overflow: hidden;
  position: relative;
}

.date {
  font-size: 12px;
  color: var(--white-text-color);
  position: absolute;
  top: 8px;
  left: 12px;
  z-index: 2;
}

.imageWraper {
  width: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    margin-top: 60%;
  }

  &:hover {
    .addCollectionBlock {
      display: flex;
    }
  }

  .collectionActions {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .addCollectionBlock {
    display: flex;
    margin-bottom: 10px;

    .addCollectionButton {
      .deleteButton{
        cursor: pointer;
        color: rgba(255, 255, 255, 0.9);
        background: var(--transparent-light-white-2);
        border-color: var(--transparent-light-black);
      }
    }
  }
}

.commentsCountWrapper {
  padding: 6px;
  color: rgba(255, 255, 255, 0.9);
  background: var(--transparent-light-white-2);
  border-color: var(--transparent-light-black);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.commentCountText {
  font-size: 11px;
  font-weight: 600;
  margin-left: 5px;
  line-height: 1;
}

.contentContainer {
  padding: 12px 12px 8px;
}

.viewButton {
  border-radius: 0;
  background: var(--primary-gradient);
  width: 100%;
}

.viewButtonText {
  font-size: 12px;
  padding: 4px;
  font-weight: 700;
}

.description {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 80px;
  margin-top: 10px;

  & p {
    margin: 0;
  }

  a {
    color: var(--primary-light-color);
  }
}

.trends {
  margin-top: 4px;
  font-weight: 700;
  font-size: 12px;
  opacity: .7;
  color: var(--white-text-color);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white-text-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.trendsContainer {
  background: var(--transparent-light-white);
  padding: 8px;
  border-radius: 2px;
  height: 76px;
}

.trendItem {
  color: var(--white-text-color);
  font-size: 12px;
  font-weight: 400;
  opacity: .7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.badgesLine {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  overflow: hidden;
  color: #535454;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #a0acb7;
  }

  .tagItem {
    margin-right: 5px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.56);
    flex-shrink: 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,rgba(0,0,0,.8),transparent 100%);

  img, svg {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: -1;
  }

  svg {
    padding: 10px;
    width: 80%;
  }
}

.emojiWrapper {
  font-size: 13px;
  display: flex;
  cursor: pointer;
  padding-top: 5px;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #a0acb7;
  }
}

.viewReportButtonContainer {
  margin-top: auto;

  a {
    width: 100%;
  }
}

.emojiTag {
  padding-right: 3px;
  font-weight: 700;
  color: #dadada;
  flex-shrink: 0;
}

.emojiTagWrap {
  background: rgba(238, 238, 238, 0.09);
  margin: 0 1px;
  border-radius: 4px;
  padding: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
}
